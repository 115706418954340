import React from "react";
import styled from "styled-components";
import { Button } from "./button";
import { CloudAppResponse } from "../../shared/cloudappresponse";

interface Props {
    addClick: () => void
    itemsClick: () => void
    items?: any[]
    title: string
    icon: string
    response?: CloudAppResponse<any>
}

interface State {

}

const DropdownsBlockDiv = styled.div`
background: white;
padding: 10px;
margin: 10px;
border-radius: 2px;
flex: 1;
box-shadow: 0px 10px 12px -5px rgba(0,0,0,0.1);
border: 2px solid rgba(0,0,0,0.05);


 .title {
     text-align: center;
     font-size: 20px;
     font-weight: 600;
     padding-top: 20px;
     padding-bottom: 20px;
 }
 .icon {
     text-align: center;
     padding: 20px;
 }

 .nav {
     display: flex;
     flex-direction: row;
 }
`

export class DropdownsBlock extends React.Component<Props, State> {

    state = {
        noPermission : undefined
    }

    componentDidUpdate() {
        let noPermission = (this.props.items && Array.isArray(this.props.items) && this.props.items.length === 1 && this.props.items[0].exceptionMessage && this.props.items[0].exceptionMessage.indexOf('does not exists') > 0);
        
        if (this.state.noPermission !== noPermission) {
            this.setState({noPermission})
        }
    }

    displayButton() {
        if (!this.props.items) return <Button text={(this.props.response?.exceptionMessage) ? this.props.response.exceptionMessage : 'loading'}
        background='none'
        color='rgba(0,0,0,0.8)'
        icon={(this.props.response) ? 'fas fa-times' : 'fas fa-circle-notch fa-spin'} />

        if (this.props.items && Array.isArray(this.props.items) && this.props.items.length === 1 && this.props.items[0].exceptionMessage) return <Button
        background='none'
        disabled
        color='rgba(0,0,0,0.8)'
        onClick={this.props.itemsClick}
        text={'No permission'} />

        if (this.props.items && Array.isArray(this.props.items)) return <Button
        background='none'
        color='rgba(0,0,0,0.8)'
        onClick={this.props.itemsClick}
        text={this.props.items.length + ' items'} />
    }

    render() {
        console.log(this.props.response);
        return <DropdownsBlockDiv>
            <div className='title'>{this.props.title} </div>
            <div className='icon'><i className={this.props.icon} /></div>
            <div className='nav'>
                <div><Button
                    disabled={(this.state.noPermission === true)}
                    background='none'
                    color='rgba(0,0,0,0.8)'
                    text='Add' icon='fal fa-edit' onClick={this.props.addClick} /></div>
                <div style={{ flex: 1 }} />
                <div>{this.displayButton()}</div>
            </div>
        </DropdownsBlockDiv>
    }
}