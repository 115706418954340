

/**  Removes any non standard characters. */
export function cleanString(str: string) {
    return str.replace(/[^a-z0-9]/gim, '').toLowerCase();
}




/**
 * Finds nested data from an object
 * @param obj source data object
 * @param str dot notation nested data path
 * @returns nested data
 */
export function objectByString(obj: any, str: string) {

    str = str.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    str = str.replace(/^\./, '');           // strip a leading dot
    const a = str.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in obj) {
            obj = obj[k];
        } else {
            return;
        }
    }
    return obj;
}


/** checks if the input string is a valid email address. */
export function validEmail(input: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)
}


export function escapeNonUnicode(str: any) {
    // eslint-disable-next-line no-useless-escape
    return str.replace(/[^a-z0-9{}\"\[\]: \.,_-]/gim, '');
}




/** Loops over an "JSON" object and sets all values to setval.
 * Prototype uses this to record the timestamps of values as they enter the system
 * so we can display timestamps per value in the UI.
 *
 */
export function recursiveSetValue(inputOrig: any, setval: any): object {
    if (inputOrig === undefined) { return setval }
    if (inputOrig == null) { return setval }

    const input = JSON.parse(JSON.stringify(inputOrig));
    Object.keys(input).map((val, index) => {
        if ((typeof input[val] === 'object') && (Array.isArray(input[val]) === false)) {
            input[val] = recursiveSetValue(input[val], setval)
        } else {
            input[val] = setval
        }
        return null;
    });

    return input;
}

export function JSONpropToCamelCase(inputobj : any) {
    let output: any = {};
    let copy = inputobj;
    Object.keys(copy).map((key, i) => {
        let keyname = key[0].toLowerCase() + key.slice(1);
        output[keyname] = inputobj[key];
        return null;
    })
    return output;
}

export function JSONpropToPascalCase(inputobj : any) {
    let output: any = {};
    let copy = inputobj;
    Object.keys(copy).map((key, i) => {
        let keyname = key[0].toUpperCase() + key.slice(1);
        output[keyname] = inputobj[key];
        return null;
    })
    return output;
}