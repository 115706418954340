import React from 'react';
import { Options, Table, InputEdit } from './styledComponents';
import { api } from '../api';
import { CloudAppResponse } from '../../shared/cloudappresponse';
import { Button } from './button';
import { DropdownForm } from './dropdownForm';
import { EmployeeForm, EmployeeFormType } from './employeeForm';
import { Link } from 'react-router-dom';
import { Loading } from './loading';
import { URLSearchParamsObj } from '../utils/urlsearchparams';
import { URLSearchProps } from '../client';

export interface Employee {
    active: boolean;
    businessGroup: string;
    employeeCompany: string;
    employeeName: string;
    employeeNumber: string;
    employeeSurname: string;
    issueDate: string;
    issueType: string;
    lastModifiedDate: string;
    laundryId: string;
    laundryName: string;
    shift: string;
    site: string;
    id: any;
}

interface State {
    employees?: Employee[];
    response_getEmployees?: CloudAppResponse<Employee[]>;
    showAddEmployeeForm: boolean;
    employeeForm?: EmployeeFormType;
    response_addEmployeeForm?: CloudAppResponseFormValidation;
    search?: string;
    searchtype?: string;
    selectedSites: string[];
}

export interface CloudAppResponseFormValidation {
    errors: { [index: string]: string[] };
    status: number;
    title: string;
}

export class Employees extends React.Component {
    state: State = {
        employees: undefined,
        response_getEmployees: undefined,
        showAddEmployeeForm: false,
        employeeForm: undefined,
        /** error response on add employee form */
        response_addEmployeeForm: undefined,

        /** search */
        search: undefined, // on search apply
        searchtype: undefined, // as user types
        selectedSites: [],
    };

    componentDidMount() {
        this.getData();
        document.title = 'Gijima | Employees';

        // handle url query params. Searching by site using query url.
        let selectedSite = URLSearchParamsObj<URLSearchProps>().site;
        if (selectedSite)
            this.setState({
                selectedSites: [selectedSite],
            });
    }

    getData() {
        if (!api.token) throw new Error('Missing api token');

        fetch(api.apiUrl + '/api/Employee/GetEmployees', {
            headers: { Authorization: 'Bearer ' + api.token.access_token },
        })
            .then((res) => res.json())
            .then((response: CloudAppResponse<Employee[]>) => {
                if (response.isSuccessful === false) {
                    this.setState({ response_getEmployees: response });
                } else {
                    this.setState({ employees: response.data });
                }
            });
    }

    addEmployee(
        employeeForm: EmployeeFormType | any,
        cb: (response: CloudAppResponse<any>) => void
    ) {
        if (employeeForm === undefined) employeeForm = {};

        if (!api.token) throw new Error('Missing api token');

        fetch(api.apiUrl + '/api/Employee/UpsertEmployee', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + api.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(employeeForm),
        })
            .then((res) => res.json())
            .then((response: CloudAppResponse<any>) => {
                cb(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    employeeSearch = (employee: Employee | any) => {
        if (this.state.selectedSites.length > 0) {
            if (this.state.selectedSites.indexOf(employee.site) === -1) {
                return;
            }
        }

        if (this.state.search !== '' && this.state.search !== undefined) {
            let propnames = Object.keys(employee);
            for (var propname of propnames) {
                try {
                    if (
                        employee[propname]
                            .toLowerCase()
                            .indexOf(this.state.search.toLowerCase()) >= 0
                    ) {
                        return employee;
                    }
                } catch (err) {}
            }
        } else {
            return employee;
        }
    };

    render() {
        // this is the url query params.. like ex: ?fullscreen=false&site=VDB
        let searchparams = URLSearchParamsObj<URLSearchProps>();

        let showHeading = true;

        if (searchparams.fullscreen === 'true') showHeading = false;

        return (
            <div>
                {showHeading && <h1>Employees</h1>}

                {this.state.showAddEmployeeForm && (
                    <DropdownForm
                        title="Add New Employee"
                        apply={() => {
                            this.addEmployee(
                                this.state.employeeForm,
                                (response) => {
                                    // if successful

                                    if (!response.isSuccessful) {
                                        /** not the usual CloudAppResponse format workaround */
                                        let response_addEmployeeForm:
                                            | CloudAppResponseFormValidation
                                            | any = response;
                                        this.setState({
                                            response_addEmployeeForm,
                                        });
                                    }

                                    if (response.isSuccessful) {
                                        this.setState({
                                            showAddEmployeeForm: false,
                                        });
                                        this.getData();
                                    }
                                }
                            );
                        }}
                        applyButtonIcon="fal fa-user-plus"
                        applyButtonText="Add Employee"
                        close={() => {
                            this.setState({ showAddEmployeeForm: false });
                        }}
                    >
                        <EmployeeForm
                            response={this.state.response_addEmployeeForm}
                            formChange={(employeeForm: EmployeeFormType) => {
                                this.setState({ employeeForm });
                            }}
                        />
                    </DropdownForm>
                )}

                <div style={{ background: 'white' }}>
                    <Options>
                        <div style={{ flex: 1 }} />

                        <InputEdit
                            value={this.state.searchtype}
                            onChange={(e) => {
                                this.setState({ searchtype: e.target.value });
                            }}
                            style={{ marginRight: 2, maxWidth: 250 }}
                            placeholder="Search..."
                        />

                        <Button
                            icon="fal fa-search"
                            style={{ marginRight: 40, marginTop: 1 }}
                            onClick={(e) => {
                                this.setState({
                                    search: this.state.searchtype,
                                });
                            }}
                        />

                        <div style={{ paddingRight: 10 }}>
                            <Button
                                text="Refresh"
                                onClick={() => {
                                    this.setState({ employees: undefined });
                                    this.getData();
                                }}
                                style={{
                                    color: 'rgba(0,0,0,0.25)',
                                    background: 'rgba(0,0,0,0)',
                                    border: '2px solid rgba(0,0,0,0)',
                                    fontWeight: 600,
                                    borderRadius: 5,
                                }}
                                icon="fa fa-redo"
                            />
                        </div>

                        <div>
                            <Button
                                text="Add employee"
                                onClick={() => {
                                    this.setState({
                                        showAddEmployeeForm: true,
                                        response_addEmployeeForm: undefined,
                                    });
                                }}
                                icon="fal fa-user-plus"
                            />
                        </div>
                    </Options>

                    <div style={{ overflow: 'auto', maxHeight: 800 }}>
                        <Table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>#</td>
                                    <td>Employee Name</td>
                                    <td>Company</td>
                                    <td>Group</td>
                                    <td>Site</td>
                                    <td>Shift</td>
                                    <td>Laundry Name</td>
                                    <td>ID</td>
                                    <td>Type</td>
                                    <td>Issued</td>
                                    <td>Modified</td>
                                </tr>
                            </thead>

                            {this.state.response_getEmployees && (
                                <tbody>
                                    <tr>
                                        <td colSpan={99}>
                                            {
                                                this.state.response_getEmployees
                                                    .exceptionMessage
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            )}

                            {this.state.employees === undefined && (
                                <tbody>
                                    <tr>
                                        <td colSpan={99}>
                                            <Loading />
                                        </td>
                                    </tr>
                                </tbody>
                            )}

                            {this.state.employees && (
                                <tbody>
                                    {this.state.employees
                                        .filter(this.employeeSearch)
                                        .map((employee, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <Link
                                                            to={
                                                                '/employees/' +
                                                                employee.employeeNumber
                                                            }
                                                        >
                                                            <i className="fad fa-edit" />{' '}
                                                            Edit
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {
                                                            employee.employeeNumber
                                                        }
                                                    </td>
                                                    <td>
                                                        {employee.employeeName}{' '}
                                                        {
                                                            employee.employeeSurname
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            employee.employeeCompany
                                                        }
                                                    </td>
                                                    <td>
                                                        {employee.businessGroup}
                                                    </td>
                                                    <td>{employee.site}</td>
                                                    <td>{employee.shift}</td>
                                                    <td>
                                                        {employee.laundryName}
                                                    </td>
                                                    <td>
                                                        {employee.laundryId}
                                                    </td>
                                                    <td>
                                                        {employee.issueType}
                                                    </td>
                                                    <td>
                                                        {employee.issueDate}
                                                    </td>
                                                    <td>
                                                        {new Date(
                                                            employee.issueDate
                                                        ).toLocaleDateString(
                                                            'en-ZA'
                                                        ) +
                                                            ' ' +
                                                            new Date(
                                                                employee.issueDate
                                                            ).toLocaleTimeString(
                                                                'en-ZA'
                                                            )}
                                                    </td>
                                                    <td>
                                                        {new Date(
                                                            employee.lastModifiedDate
                                                        ).toLocaleDateString(
                                                            'en-ZA'
                                                        ) +
                                                            ' ' +
                                                            new Date(
                                                                employee.lastModifiedDate
                                                            ).toLocaleTimeString(
                                                                'en-ZA'
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            )}
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}
