import React from "react";
import { Site, Laundry, BusinessGroup, Shift, Company } from "./dropdowns";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { api } from "../api";
import { FormTable, FormSelect, FormLabel, InputEdit } from "./styledComponents";
import { formatDate } from "../../shared/date";
import { CloudAppResponseFormValidation } from "./employees";

interface Props {
    formChange: (employeeForm: EmployeeFormType) => void
    value?: EmployeeFormType
    response?: CloudAppResponseFormValidation
}

interface States {
    sites?: Site[]
    laundries?: Laundry[]
    businessGroups?: BusinessGroup[]
    shifts?: Shift[]
    companies?: Company[],
    employeeForm: EmployeeFormType
}

export interface EmployeeFormType {
    active: boolean,
    employeeNumber: string,
    employeeName: string,
    employeeSurname: string,
    employeeCompany: string,
    site: string,
    shift: string,
    businessGroup: string,
    laundryName: string,
    laundryId: string,
    issueType: string,
    issueDate: string
}

export class EmployeeForm extends React.Component<Props, States> {
    state: States = {
        sites: undefined,
        laundries: undefined,
        businessGroups: undefined,
        shifts: undefined,
        companies: undefined,
        employeeForm: {
            active: true,
            employeeNumber: "",
            employeeName: "",
            employeeSurname: "",
            employeeCompany: "",
            site: "",
            shift: "",
            businessGroup: "",
            laundryName: "",
            laundryId: "",
            issueType: "",
            issueDate: new Date().toISOString()
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ employeeForm: this.props.value })
        }
        this.getData();
    }

    getData() {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/DropDowns/GetSites',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Site[]>) => {
                if (response.data) { this.setState({ sites: response.data }); } else {
                    this.setState({ sites: [] })
                }
            }).catch(err => { this.setState({ sites: [] }) });

        fetch(api.apiUrl + '/api/DropDowns/GetLaundries',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Laundry[]>) => {
                if (response.data) {
                    this.setState({ laundries: response.data });
                } else {
                    this.setState({ laundries: [] });
                }
            }).catch(err => { this.setState({ laundries: [] }) });

        fetch(api.apiUrl + '/api/DropDowns/GetBusinessGroups',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<BusinessGroup[]>) => {
                if (response.data) {
                    this.setState({ businessGroups: response.data });
                } else {
                    this.setState({ businessGroups: [] })
                }
            }).catch(err => { this.setState({ businessGroups: [] }) });

        fetch(api.apiUrl + '/api/DropDowns/GetShifts',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Shift[]>) => {
                if (response.data) {
                    this.setState({ shifts: response.data });
                } else {
                    this.setState({ shifts: [] });
                }
            }).catch(err => { this.setState({ shifts: [] }) });

        fetch(api.apiUrl + '/api/DropDowns/GetCompanies',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Company[]>) => {
                if (response.data) {
                    this.setState({ companies: response.data });
                } else {
                    this.setState({ companies: [] });
                }

            }).catch(err => { this.setState({ companies: [] }) });

    }

    changeForm = (value: any) => {
        this.setState(value);
        this.props.formChange(this.state.employeeForm); // let parent know of new state.
    }

    render() {

        if (this.state.sites === undefined) return <div>loading..</div>
        if (this.state.laundries === undefined) return <div>loading..</div>
        if (this.state.businessGroups === undefined) return <div>loading..</div>
        if (this.state.shifts === undefined) return <div>loading..</div>
        if (this.state.companies === undefined) return <div>loading..</div>

        return <FormTable style={{ width: '100%' }}>
            <tbody>

                <tr>
                    <FormLabel>Active:</FormLabel>
                    <td>
                        <FormSelect
                            value={(this.state.employeeForm.active) ? 'true' : 'false'}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.active = (e.target.value === 'true') ? true : false;
                                this.changeForm({ employeeForm })
                            }}
                        >
                            <option value='true'>True</option>
                            <option value='false'>False</option>
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='active' /></td>
                </tr>

                <tr>
                    <FormLabel>Employee Number:</FormLabel>
                    <td>
                        <InputEdit value={this.state.employeeForm.employeeNumber}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.employeeNumber = e.target.value;
                                this.changeForm({ employeeForm })
                            }} />
                    </td>
                    <td><FormResponse data={this.props.response} field='employeeNumber' /></td>
                </tr>
                <tr>
                    <FormLabel>Employee Name:</FormLabel>
                    <td><InputEdit value={this.state.employeeForm.employeeName}
                        onChange={(e) => {
                            const employeeForm = this.state.employeeForm;
                            employeeForm.employeeName = e.target.value;
                            this.changeForm({ employeeForm })
                        }}
                    /></td>
                    <td><FormResponse data={this.props.response} field='employeeName' /></td>
                </tr>
                <tr>
                    <FormLabel>Employee Surname:</FormLabel>
                    <td><InputEdit
                        value={this.state.employeeForm.employeeSurname}
                        onChange={(e) => {
                            const employeeForm = this.state.employeeForm;
                            employeeForm.employeeSurname = e.target.value;
                            this.changeForm({ employeeForm })
                        }}
                    /></td>
                    <td><FormResponse data={this.props.response} field='employeeSurname' /></td>
                </tr>
                <tr>
                    <FormLabel>Employee Company:</FormLabel>
                    <td>
                        <FormSelect
                            value={this.state.employeeForm.employeeCompany}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.employeeCompany = e.target.value;
                                this.changeForm({ employeeForm: employeeForm })
                            }}
                        >
                            <option value='' disabled>Select a Company</option>
                            {this.state.companies.map((company, i) => {
                                return <option key={i} value={company.name}>{company.name}</option>
                            })}
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='employeeCompany' /></td>
                </tr>
                <tr>
                    <FormLabel>Site:</FormLabel>
                    <td>
                        <FormSelect
                            value={this.state.employeeForm.site}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.site = e.target.value;
                                this.changeForm({ employeeForm })
                            }}
                        >
                            <option value='' disabled>Select a Site</option>
                            {this.state.sites.map((site, i) => {
                                return <option key={i} value={site.name}>{site.name}</option>
                            })}
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='site' /></td>
                </tr>
                <tr>
                    <FormLabel>Shift:</FormLabel>
                    <td>
                        <FormSelect
                            value={this.state.employeeForm.shift}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.shift = e.target.value;
                                this.changeForm({ employeeForm })
                            }}
                        >
                            <option value='' disabled>Select a Shift</option>
                            {this.state.shifts.map((shift, i) => {
                                return <option key={i} value={shift.name}>{shift.name}</option>
                            })}
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='shift' /></td>
                </tr>
                <tr>
                    <FormLabel>Business Group:</FormLabel>
                    <td>
                        <FormSelect
                            value={this.state.employeeForm.businessGroup}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.businessGroup = e.target.value;
                                this.changeForm({ employeeForm })
                            }}>
                            <option value='' disabled>Select a Business Group</option>
                            {this.state.businessGroups.map((businessGroup, i) => {
                                return <option key={i} value={businessGroup.name}>{businessGroup.name}</option>
                            })}
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='businessGroup' /></td>
                </tr>

                <tr>
                    <FormLabel>Laundry:</FormLabel>
                    <td>
                        <FormSelect
                            value={(this.state.employeeForm.laundryName === null) ? '' : this.state.employeeForm.laundryName}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.laundryName = e.target.value;
                                this.changeForm({ employeeForm })
                            }}>
                            <option value='' disabled>Select a Laundry</option>
                            {this.state.laundries.map((laundry, i) => {
                                return <option key={i} value={laundry.name}>{laundry.name}</option>
                            })}
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='laundryName' /></td>
                </tr>
                <tr>
                    <FormLabel>Laundry ID:</FormLabel>
                    <td>
                        <InputEdit
                            type='text'
                            value={this.state.employeeForm.laundryId}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.laundryId = e.target.value;
                                this.changeForm({ employeeForm })
                            }} />
                    </td>
                    <td><FormResponse data={this.props.response} field='LaundryId' /></td>
                </tr>
                <tr>
                    <FormLabel>Issue Type:</FormLabel>
                    <td>
                        <FormSelect
                            value={this.state.employeeForm.issueType}
                            onChange={(e) => {
                                const employeeForm = this.state.employeeForm;
                                employeeForm.issueType = e.target.value;
                                this.changeForm({ employeeForm })
                            }}
                        >
                            <option value='' disabled>Select Issue Type</option>
                            <option value='Used'>Used</option>
                            <option value='New'>New</option>
                            <option value='Old'>Old</option>
                        </FormSelect>
                    </td>
                    <td><FormResponse data={this.props.response} field='IssueType' /></td>
                </tr>
                <tr>
                    <FormLabel>Overall Issue Date:</FormLabel>
                    <td><InputEdit type='date'
                        value={formatDate(this.state.employeeForm.issueDate)}
                        onChange={(e) => {
                            const employeeForm = this.state.employeeForm;
                            employeeForm.issueDate = new Date(e.target.value).toISOString();
                            this.setState({ employeeForm })
                        }}
                    /></td>
                    <td><FormResponse data={this.props.response} field='IssueDate' /></td>
                </tr>
                {(this.props.response) && <tr><td colSpan={99} style={{ color: 'red', textAlign: 'center', fontSize: 14 }}>
                    <i className='fa fa-times' /> Error. {this.props.response.title}
                </td>
                </tr>}
            </tbody>

        </FormTable >
    }
}

export function FormResponse(props : any ) {
    if (!props.data) { return null }
    if (!props.data.errors) { return null }

    if (!props.data.errors[props.field]) { return <div style={{ color: 'green' }}><i className='fa fa-check' /></div> }

    return <div>
        {props.data.errors[props.field].map((text:string, key:number) => {
            return <div key={key} style={{ color: 'red' }}>
                <i className='fa fa-times' /> Error. {text}
            </div>
        })}
    </div>
}