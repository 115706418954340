import React from "react";
import { api } from "../api";
import { encodeQueryData } from "../../shared/encodeQueryData";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { Employee } from "./employees";
import { Loading } from "./loading";
import { Button } from "./button";
import { EmployeeForm } from "./employeeForm";
import { JSONpropToCamelCase } from "../../shared/shared";
import { Header3, Table, TextButton, InputEdit, FormLabel, FormSelect, FormTable, CloudAppResponseDisplay } from "./styledComponents";
import { TagGarment, TagStatusHistory, TagGarmentRequest } from "./dashboard";
import { GarmentType } from "./dropdowns";

interface Props {
    match: {
        params: {
            employeeNumber: string
        }
    }
}

interface States {
    employee?: Employee
    tagGarments?: TagGarment[]
    tagStatusHistory?: TagStatusHistory[]
    allocateTagForm: TagGarmentRequest
    response_editEmployee?: CloudAppResponse<any>
    response_allocateTag?: CloudAppResponse<any>
    garmentTypes: GarmentType[]
}



export class EmployeePage extends React.Component<Props, States> {
    state: States = {
        employee: undefined,
        tagGarments: undefined,
        tagStatusHistory: undefined,
        allocateTagForm: {
            employeeNumber: '',
            garmentTypeId: '',
            garmentSize: 36,
            tagId: '',
            laundryId: '',
            issueDate: '',
            issueType: ''
        },
        response_editEmployee: undefined,
        response_allocateTag: undefined,
        garmentTypes: []
    }

    componentDidMount() {
        this.getEmployee();
        this.getTagData();
        this.getGarmentTypes();
        document.title = 'Gijima | Employee ' + this.props.match.params.employeeNumber
    }

    getEmployee() {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Employee/GetEmployee?' + encodeQueryData({ employeeNumber: this.props.match.params.employeeNumber }),
            {
                headers: { Authorization: 'Bearer ' + api.token.access_token }
            }).then(res => res.json()).then((response: CloudAppResponse<any>) => {
                if (response.isSuccessful) {
                    this.setState({ employee: response.data });
                }
            }).catch(err => {
                console.log(err);
            })
    }

    getTagData() {
        this.GetAllTagGarments();
        this.GetAllTagStatusHistory('all', (tagStatusHistory) => { this.setState({ tagStatusHistory }) })
    }

    getGarmentTypes() {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/DropDowns/GetGarmentTypes',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<GarmentType[]>) => {
                if (response.data) this.setState({ garmentTypes: response.data });
            }).catch(err => { this.setState({ garmentTypes: [] }) });
    }

    GetAllTagGarments() {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/TagGarment/GetAllTagGarments',
            {
                method: 'get',
                headers: { Authorization: 'Bearer ' + api.token.access_token }
            })
            .then(res => res.json()).then((response: CloudAppResponse<TagGarment[]>) => {
                if (response.isSuccessful === undefined) {
                    console.log(new Error('Missing isSuccessful property'));
                    return;
                }
                if (response.isSuccessful) {
                    if (response.data) this.setState({ tagGarments: response.data })
                } else {
                    console.error(response);
                }

            }).catch(err => { console.log(err) });
    }

    GetAllTagStatusHistory(inOrOut: 'all' | 'in' | 'out', cb: (tagStatusHistory: TagStatusHistory[]) => void) {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/TagGarment/GetAllTagStatusHistory?' + encodeQueryData({ inOrOut }),
            {
                method: 'get',
                headers: { Authorization: 'Bearer ' + api.token.access_token }
            })
            .then(res => res.json()).then((response: CloudAppResponse<TagStatusHistory[]>) => {
                if (response.isSuccessful === undefined) {
                    console.log(new Error('Missing isSuccessful property'));
                    return;
                }
                if (response.isSuccessful) {
                    if (response.data) cb(response.data);
                } else {
                    console.error(response);
                }

            }).catch(err => { console.log(err); });
    }

    allocateTagGarment = (tagGarmentRequest: TagGarmentRequest, cb: (response: CloudAppResponse<any> | any) => void) => {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/TagGarment/UpsertTagGarment',
            {
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + api.token.access_token,
                    'Content-Type': 'application/json-patch+json'
                },
                body: JSON.stringify(tagGarmentRequest)
            })
            .then(res => res.json()).then((response: CloudAppResponse<any>) => {
                cb(response);
            }).catch(err => { console.log(err); });
    }

    deAllocateTagGarment = (tagId: string) => {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/TagGarment/DeallocateTagGarment',
            {
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + api.token.access_token,
                    'Content-Type': 'application/json-patch+json'
                },
                body: JSON.stringify(tagId)
            })
            .then(res => res.json()).then((response: CloudAppResponse<any>) => {
                if (response.isSuccessful === undefined) {
                    console.log(response); return;
                }
                this.getTagData(); // reload tags
            }).catch(err => { console.log(err); });
    }

    updateEmployee(employee: Employee, cb: (response: CloudAppResponse<any>/* | CloudAppErrorResponse*/) => void) {

        // workaround
        // employee.id = employee.id;
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Employee/UpsertEmployee',
            {
                method: 'post',
                headers: { Authorization: 'Bearer ' + api.token.access_token, 'Content-Type': 'application/json-patch+json' },
                body: JSON.stringify(employee)
            }).then(res => res.json()).then((response: CloudAppResponse<any>) => {
                cb(response);
            }, err => { 
                console.error(err);
                /*
                var resError: CloudAppErrorResponse = {
                    isSuccessful: false,
                     error: err 
                };
                cb(resError);
                */
            });
    }

    render() {
        if (this.state.employee === undefined) return <Loading />
        if (this.state.tagGarments === undefined) return <Loading />
        if (this.state.tagStatusHistory === undefined) return <Loading />

        // filter for this employee
        let tagHistoryEmployee = (this.state.tagStatusHistory) ? this.state.tagStatusHistory.filter(tagStatusHistory => (tagStatusHistory.employeeNumber === this.state.employee?.employeeNumber)) : []


        return (<div>
            <h1>Employee Page</h1>
            <div style={{ background: 'white' }} >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1, padding: '20px 10px 0px 20px' }}>
                        <div style={{
                            border: '2px solid rgba(0,0,0,0.1)',
                            borderRadius: 2
                        }}>
                            <div style={{ padding: 20 }}>
                                <Header3 style={{ marginBottom: 30 }}>Details:</Header3>
                                {(this.state.employee === undefined) && <div><Loading text='Employee information' /></div>}

                                {(this.state.employee) && <EmployeeForm value={JSONpropToCamelCase(this.state.employee)} formChange={(employee) => {
                                    this.setState({ employee: JSONpropToCamelCase(employee) })
                                }} />}
                            </div>

                            <div style={{ padding: '0px 30px 25px 0px', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, padding: 5, paddingRight: 20, textAlign: 'right' }} ><CloudAppResponseDisplay data={this.state.response_editEmployee} /></div>
                                <Button
                                    text={'Save Employee Details'}
                                    onClick={() => {
                                        this.setState({ response_editEmployee: undefined })

                                        if (this.state.employee) this.updateEmployee(this.state.employee, (response) => {
                                            //const results = response as CloudAppResponse<any>;
                                            this.setState({ response_editEmployee: response });// results })
                                        });

                                    }}
                                    icon='fal fa-save' />
                            </div>
                        </div>
                    </div>

                    <div style={{ flex: 1, padding: '20px 20px 0px 10px' }}>
                        <div style={{
                            marginBottom: 20,
                            border: '2px solid rgba(0,0,0,0.1)',
                            borderRadius: 2
                        }}>
                            <Header3 style={{ margin: 20 }}>Allocate Tag:</Header3>
                            <FormTable style={{ padding: 20 }}>
                                <tbody>
                                    <tr>
                                        <FormLabel>Issue Type:</FormLabel>
                                        <td>
                                            <FormSelect
                                                value={this.state.allocateTagForm.issueType}
                                                onChange={(e) => {
                                                    const allocateTagForm = this.state.allocateTagForm;
                                                    allocateTagForm.issueType = e.target.value;
                                                    this.setState({ allocateTagForm })
                                                }}
                                            >   <option value='' disabled>Select</option>
                                                <option value='Used'>Used</option>
                                                <option value='New'>New</option>
                                                <option value='Old'>Old</option>
                                            </FormSelect>
                                        </td>
                                    </tr>
                                    <tr>
                                        <FormLabel>Tag Number:</FormLabel>
                                        <td>
                                            <InputEdit type='text'
                                                value={this.state.allocateTagForm.tagId}
                                                onChange={(e) => {
                                                    const allocateTagForm = this.state.allocateTagForm;
                                                    allocateTagForm.tagId = e.target.value;
                                                    this.setState({ allocateTagForm: allocateTagForm })
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <FormLabel>Garment Type:</FormLabel>
                                        <td>
                                            <FormSelect
                                                value={this.state.allocateTagForm.garmentTypeId}
                                                onChange={(e) => {
                                                    const allocateTagForm = this.state.allocateTagForm;
                                                    allocateTagForm.garmentTypeId = e.target.value;
                                                    this.setState({ allocateTagForm: allocateTagForm })
                                                }}>
                                                <option value='' disabled>Select a Garment Type</option>
                                                {this.state.garmentTypes.map((garment, i) => {
                                                    return <option key={i} value={garment.id}>{garment.name}</option>
                                                })}
                                            </FormSelect>
                                        </td>
                                    </tr>
                                    <tr>
                                        <FormLabel>garmentSize:</FormLabel>
                                        <td><InputEdit type='number'
                                            value={this.state.allocateTagForm.garmentSize}
                                            onChange={(e) => {
                                                let size = 25;
                                                if (e.target.value != null) {
                                                    size = parseInt(e.target.value, 10);
                                                }

                                                if (size >= 10) { if (size < 25) { size = 25 } }
                                                if (size > 60) { size = 60 }
                                                const allocateTagForm = this.state.allocateTagForm;
                                                allocateTagForm.garmentSize = size;
                                                this.setState({ allocateTagForm: allocateTagForm })
                                            }}
                                        /></td>
                                    </tr>
                                </tbody>
                            </FormTable>
                            <div style={{ padding: '0px 30px 25px 0px', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, padding: 5, paddingRight: 20, textAlign: 'right' }} ><CloudAppResponseDisplay data={this.state.response_allocateTag} /></div>
                                <Button
                                    text={'Allocate Tag'}
                                    onClick={() => {

                                        if (!this.state.employee) throw new Error('employee data missing');

                                        let allocateTagForm: TagGarmentRequest = this.state.allocateTagForm
                                        allocateTagForm.employeeNumber = this.state.employee.employeeNumber;
                                        allocateTagForm.issueDate = new Date().toISOString();
                                        allocateTagForm.laundryId = this.state.employee.laundryId;
                                        
                                        this.setState({ response_allocateTag: undefined });

                                        this.allocateTagGarment(allocateTagForm, (response) => {
                                            this.setState({ response_allocateTag: response });

                                            if (response.isSuccessful) {
                                                this.getTagData();
                                            } else {
                                                if (response.errors) {
                                                    if (response.errors['tagGarmentRequest']) {
                                                        this.setState({ response_allocateTag: { isSuccessful: false, message: response.errors['tagGarmentRequest'][0] } });
                                                    }
                                                }

                                            }

                                        });
                                    }}
                                    icon='fal fa-tag' />
                            </div>
                        </div>



                    </div>
                </div>

                <div style={{ padding: 20 }}>
                    <Header3 style={{ marginBottom: 10 }}>Tag Garments:</Header3>

                    <Table>
                        <thead>
                            <tr>
                                <td>Tag#</td>
                                <td>Garment Size</td>
                                <td>Garment Type</td>
                                <td>Allocated By</td>
                                <td>Allocated Date</td>
                                <td>Tag</td>
                                <td>Tag Last Seen</td>
                                <td>Tag Issue Date</td>
                                <td>Tag Issue Type</td>
                                <td></td>
                            </tr>
                        </thead>
                        {(this.state.tagGarments) && <tbody>{this.state.tagGarments
                            .filter(tagGarment => (tagGarment.employeeNumber === this.state.employee?.employeeNumber))
                            .map((tagGarment, i) => {

                                let tagHist = tagHistoryEmployee.filter(tag => (tag.tagId === tagGarment.tagId))[0]

                                if (!tagHist) { return null }

                                let styleStatusInOut: any = { background: 'white' }
                                if (tagHist.tagStatus) {
                                    if (tagHist.tagStatus === 'In') {
                                        styleStatusInOut.background = 'rgba(137,209,94,0.1)'
                                        styleStatusInOut.color = 'rgba(137,209,94,1)'
                                    } else {
                                        styleStatusInOut.background = 'rgba(218,100,105,0.1)'
                                        styleStatusInOut.color = 'rgba(218,100,105,1)'
                                    }
                                }

                                try {
                                    return <tr key={i} style={{
                                        background: styleStatusInOut.background
                                    }}>
                                        <td>{tagGarment.tagId}</td>
                                        <td>{tagGarment.garmentSize}</td>
                                        <td>{tagGarment.garmentType}</td>
                                        <td>{tagGarment.allocatedBy}</td>
                                        <td>{tagGarment.allocatedDate}</td>
                                        <td style={{ color: styleStatusInOut.color }}>{tagHist.tagStatus}</td>
                                        <td>{tagHist.tagLastSeen}</td>
                                        <td>{tagHist.issueDate}</td>
                                        <td>{tagHist.issueType}</td>
                                        <td><TextButton onClick={() => {
                                            this.deAllocateTagGarment(tagGarment.tagId);
                                        }}><i className='fal fa-tag' /> Deallocate</TextButton></td>
                                    </tr>
                                } catch (err: any) {
                                    return <tr key={i}><td colSpan={99}>error: {err?.message}</td></tr>
                                }
                            })}</tbody>}
                    </Table>

                </div>
            </div>
        </div>
        )

    }
}
