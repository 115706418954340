import React from "react";
import { api } from "../api";
import styled from "styled-components";
import { Button } from "./button";
import { DropdownForm } from "./dropdownForm";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { Site, Company } from "./dropdowns";
import { Role } from "./roles_page";
import { TagButton, Options, Table, CloudAppResponseDisplay } from "./styledComponents";
import { InputSelect } from "./inputselect";

interface User {
    id: string
    username: string
    email: string
    roleId: string[]
    siteId: string[]
    companyId: string[]
}




const Input = styled.input`
    padding:10px;
    border: 1px solid rgba(0,0,0,0.25);
    width: 100%;
`;

interface Props { }
interface State {
    users: User[],
    roles: Role[],
    sites: Site[],
    companies: Company[],
    showInviteForm: boolean
    inviteFormContent: User
    response_inviteUser?: CloudAppResponse<any>
}

export class UsersPage extends React.Component<Props, State> {

    state: State = {
        users: [],
        roles: [],
        sites: [],
        companies: [],
        showInviteForm: false,
        inviteFormContent: {
            id: '',
            username: '',
            email: '',
            roleId: ["1"],
            siteId: ["1"],
            companyId: ["1"]
        },
        response_inviteUser: undefined
    }

    componentDidMount() {
        this.getData();
        document.title = 'Gijima | Manage Users'
    }

    getData() {

        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/User/GetAllUsers',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<User[]>) => {
                if (response.isSuccessful && response.data) this.setState({ users: response.data });
            })

        fetch(api.apiUrl + '/api/Role/GetAllRoles',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Role[]>) => {
                if (response.isSuccessful && response.data) this.setState({ roles: response.data });
            })

        fetch(api.apiUrl + '/api/DropDowns/GetSites',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Site[]>) => {
                if (response.isSuccessful && response.data) this.setState({ sites: response.data });
            }).catch(err => { this.setState({ sites: [] }) })

        fetch(api.apiUrl + '/api/DropDowns/GetCompanies',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<Company[]>) => {
                if (response.isSuccessful && response.data) this.setState({ companies: response.data });
            }).catch(err => { this.setState({ companies: [] }) });
    }

    render() {
        if (!api.token) throw new Error('token missing');

        return <div style={{ background: 'white' }}>

            {(this.state.showInviteForm) && <DropdownForm
                title='Invite User'
                apply={() => {
                    if (!api.token) throw new Error('token missing');

                    fetch(api.apiUrl + '/api/User/UpsertUserDetail', {
                        method: 'post', headers: {
                            Authorization: 'Bearer ' + api.token.access_token,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }, body: JSON.stringify(this.state.inviteFormContent)
                    }).then(res => res.json()).then(response => {
                        this.setState({ response_inviteUser: response });
                        if (response.isSuccessul) {
                            this.setState({ showInviteForm: false })
                        }
                    })
                }}
                applyButtonIcon='fal fa-envelope'
                applyButtonText='Send Invite'
                close={() => { this.setState({ showInviteForm: false }); }}
            >
                <div>
                    username:<br />
                    <Input
                        type='text'
                        value={this.state.inviteFormContent.username}
                        onChange={(e) => {
                            let inviteFormContent = this.state.inviteFormContent;
                            inviteFormContent.username = e.target.value;
                            this.setState({ inviteFormContent })
                        }}
                    />
                </div>
                <div style={{ paddingTop: 10 }}>
                    email: <br />
                    <Input
                        type='email'
                        value={this.state.inviteFormContent.email}
                        onChange={(e) => {
                            let inviteFormContent = this.state.inviteFormContent;
                            inviteFormContent.email = e.target.value;
                            this.setState({ inviteFormContent })
                        }}
                    />
                </div>

                <div style={{ paddingTop: 10 }}>
                    Roles: <br />
                    {this.state.inviteFormContent.roleId.map((roleId) => {
                        for (let role of this.state.roles) {
                            if (role.id === roleId) {
                                return <TagButton
                                    deleteClick={() => {
                                        if (!this.state.inviteFormContent) return;
                                        const inviteFormContent = this.state.inviteFormContent;
                                        inviteFormContent.roleId = inviteFormContent.roleId.filter((roleIdCur) => (roleIdCur !== roleId))
                                        this.setState({ inviteFormContent })
                                    }}
                                    key={roleId}>{role.roleName}</TagButton>
                            }
                        }
                        return null;
                    })}
                </div>

                <div style={{ marginTop: 5, position: 'relative', zIndex: 3 }}>
                    <InputSelect
                        onChange={(e) => {
                            if (!this.state.inviteFormContent) return;

                            // check for double..
                            if (this.state.inviteFormContent.roleId.indexOf(e.target.value) === -1) {
                                const inviteFormContent = this.state.inviteFormContent;
                                inviteFormContent.roleId.push(e.target.value);
                                this.setState({ inviteFormContent })
                            }
                        }}
                        placeholder='Add role'
                        value=''
                        datalist={this.state.roles.map((role) => { return { value: role.id, text: role.roleName } })} />
                </div>

                <div style={{ paddingTop: 10 }}>
                    Sites: <br />
                    {this.state.inviteFormContent.siteId.map((siteId) => {
                        for (let site of this.state.sites) {
                            if (site.id === siteId) {
                                return <TagButton
                                    deleteClick={() => {
                                        if (!this.state.inviteFormContent) return;
                                        const inviteFormContent = this.state.inviteFormContent;
                                        inviteFormContent.siteId = inviteFormContent.siteId.filter((siteIdCur) => (siteIdCur !== siteId))
                                        this.setState({ inviteFormContent })
                                    }}
                                    key={siteId}>{site.name}</TagButton>
                            }
                        }
                        return null;
                    })}
                </div>

                <div style={{ marginTop: 5 }}>
                    <InputSelect
                        onChange={(e) => {
                            if (!this.state.inviteFormContent) return;
                            
                            // check for double..
                            if (this.state.inviteFormContent.siteId.indexOf(e.target.value) === -1) {
                                const inviteFormContent = this.state.inviteFormContent;
                                inviteFormContent.siteId.push(e.target.value);
                                this.setState({ inviteFormContent });
                            }
                        }}
                        placeholder='Add site'
                        value=''
                        datalist={this.state.sites.map((site) => { return { value: site.id, text: site.name } })} />
                </div>


                <div style={{ paddingTop: 10 }}>
                    Companies: <br />
                    {this.state.inviteFormContent.companyId.map((companyId) => {
                        for (let company of this.state.companies) {
                            if (company.id === companyId) {
                                return <TagButton
                                    deleteClick={() => {
                                        const inviteFormContent = this.state.inviteFormContent;
                                        inviteFormContent.companyId = inviteFormContent.companyId.filter((companyIdCur) => (companyIdCur !== companyId))
                                        this.setState({ inviteFormContent })
                                    }}
                                    key={companyId}>{company.name}</TagButton>
                            }
                        }
                        return null;
                    })}
                </div>

                <div style={{ marginTop: 5 }}>
                    <InputSelect
                        onChange={(e) => {
                            // check for double..
                            if (this.state.inviteFormContent.companyId.indexOf(e.target.value) === -1) {
                                this.state.inviteFormContent.companyId.push(e.target.value);
                                this.setState({ inviteFormContent: this.state.inviteFormContent })
                            }
                        }}
                        placeholder='Add company'
                        value=''
                        datalist={this.state.companies.map((company) => { return { value: company.id, text: company.name } })} />
                </div>
                <div style={{
                    textAlign: 'right',
                    paddingRight: 10
                }}>
                    <CloudAppResponseDisplay
                        data={this.state.response_inviteUser} />
                </div>
            </DropdownForm>
            }

            <Options>
                <div style={{ flex: 1 }} />

                <div style={{ paddingRight: 10 }}>
                    <Button text='Refresh'
                        onClick={() => {
                            this.setState({ users: [] })
                            this.getData();
                        }}
                        style={{
                            color: 'rgba(0,0,0,0.25)',
                            background: 'rgba(0,0,0,0)',
                            border: '2px solid rgba(0,0,0,0)',
                            fontWeight: 600,
                            borderRadius: 5
                        }}
                        icon='fa fa-redo' />
                </div>

                <div>
                    <Button text='Invite User'
                        onClick={() => { 
                            this.setState({ showInviteForm: true,
                                inviteFormContent: {
                                id: '',
                                username: '',
                                email: '',
                                roleId: ["1"],
                                siteId: ["1"],
                                companyId: ["1"]
                            }, response_inviteUser: undefined });
                        }}
                        style={{
                            background: '#0d3859',
                            borderRadius: 5
                        }}
                        icon='fal fa-user-plus' />
                </div>
            </Options>


            <Table style={{ padding: 20 }}>
                <thead>
                    <tr>
                        <td>id</td>
                        <td>Username</td>
                        <td>Email</td>
                        <td>Roles</td>
                        <td>Sites</td>
                        <td>Companies</td>
                    </tr>
                </thead>

                {(this.state.users) && <tbody>
                    {(this.state.users.length === 0) && <tr><td colSpan={99}>NO DATA</td></tr>}
                    {(this.state.users.map((user, i) => {
                        return <tr key={i}>
                            <td>{user.id}</td>
                            <td>{user.username}</td>
                            <td>{user.email}</td>

                            <td>
                                {/* Roles */}
                                {user.roleId.map((roleId) => {
                                    return <TagButton
                                        deleteClick={() => {
                                            user.roleId = user.roleId.filter((role) => (role !== roleId))
                                            this.setState({ users: this.state.users })
                                        }}
                                        key={roleId}>{roleId}</TagButton>
                                })}
                            </td>

                            <td>
                                {/* Sites */}
                                {user.siteId.map((siteId) => {
                                    return <TagButton
                                        deleteClick={() => {
                                            user.siteId = user.siteId.filter((site) => (site !== siteId))
                                            this.setState({ users: this.state.users })
                                        }}
                                        key={siteId}>{siteId}</TagButton>

                                })}
                            </td>


                            <td>
                                {/* Companies */}
                                {user.companyId.map((companyId) => {
                                    return <TagButton
                                        deleteClick={() => {
                                            user.companyId = user.companyId.filter((company) => (company !== companyId))
                                            this.setState({ users: this.state.users })
                                        }}
                                        key={companyId}>{companyId}</TagButton>
                                })}
                            </td>
                        </tr>
                    }))}
                </tbody>}
            </Table>
        </div >
    }
}