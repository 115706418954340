import { EventEmitter } from 'events';

import url from 'url';
import jwt_decode from "jwt-decode";
import history from './utils/history'
import { Permission } from './components/permissions_page';
import { CloudAppResponse } from '../shared/cloudappresponse';
import { formatDate } from '../shared/date';
import { JSONpropToCamelCase } from '../shared/shared';
import { Theme, ThemesType } from '@iotnxt-dev/reactcomponents';

interface AppSettings {
    apiUrl:string
    commanderUrl:string
    client_id:string
}
class API extends EventEmitter {
    account?: IoTTokenDecoded;
    token?: Token;

    // DEV DEFAULTS
    commanderUrl = '';
    apiUrl = '';
    client_id = '';
    theme?: ThemesType = Theme.defaultThemeData.themes['default'];

    
    appSettingsGet = async () => {               
        const appSettings : CloudAppResponse<AppSettings> = await fetch('/api/AppSettings/Get').then((res) => { return res.json(); });
        
        if (appSettings && appSettings.data) {
            this.apiUrl = appSettings.data.apiUrl;
            this.commanderUrl = appSettings.data.commanderUrl;
            this.client_id = appSettings.data.client_id;
        }
        
        return;        
    }


    auth = async () => {
        //localStorage.removeItem('token');
        let gettoken = localStorage.getItem('token');

        if (gettoken) {
            let token: Token = JSON.parse(gettoken);
            console.log('tokenfromstorage', token);
            if (!token.expires) localStorage.removeItem('token');
            if (token.expires) {
                let timeleft = new Date(token.expires).getTime() - new Date().getTime();
                if (timeleft > 0) {
                    console.log("token expires in", timeleft);

                    this.token = token;
                    let account: IoTTokenDecoded = jwt_decode(token.access_token)
                    this.account = account;
                    // this.emit('login');
                    // return;
                };
            }
        }

        if (this.account) { return; }

        console.log('auth');
        await this.appSettingsGet();

        if (window.location.pathname === '/silent-refresh.html') {
            const x = window.location.hash.slice(1).split('&')

            const decoded: any = {};
            for (const y of x) {
                const c = y.split('=');
                decoded[c[0]] = unescape(c[1])
            }
            let token: Token = decoded;
            let expires = new Date();
            expires.setTime(new Date().getTime() + parseInt(token.expires_in) * 1000);
            token.expires = expires.toISOString();
            this.token = token;

            localStorage.setItem('token', JSON.stringify(token));

            let account: IoTTokenDecoded = jwt_decode(token.access_token)
            this.account = account;
            this.emit('login');
            let state = JSON.parse(token.state);
            history.push(`${state.pathname}${state.search}`);
        } else {
            let state = {
                pathname: window.location.pathname,
                search: window.location.search
            }

            let protocol = window.location.protocol.split(':')[0]

            const redirect_uri = url.format({
                protocol,
                host: window.location.host,
                pathname: '/silent-refresh.html'
            });

            const query = {
                response_type: 'token',
                client_id: this.client_id,
                redirect_uri,
                scope: 'permissions api',
                state: JSON.stringify(state)
            }
            const urlredirect = this.commanderUrl + '/auth/connect/authorize?' + encodeQueryData(query);
            window.location.replace(urlredirect)
            // history.push(`${urlredirect}`);
        }

    }

    getAllPermissions(cb: (permissions: Permission[]) => void) {
        if (!this.token) throw new Error('no token');

        fetch(api.apiUrl + '/api/Permission/GetAllPermissions',
            { headers: { Authorization: 'Bearer ' + this.token.access_token } })
            .then(res => res.json()).then((response: CloudAppResponse<Permission[]>) => {

                if (!response.data) throw new Error(response.message || response.exceptionMessage)

                cb(response.data.sort((a, b) => { if (parseInt(a.id, 10) > parseInt(b.id, 10)) { return 1 } else { return -1 } }));
            })
    }

    permissionUpsertPermissionDetail(permission: Permission, cb?: () => void) {
        if (!this.token) throw new Error('no token');

        fetch(api.apiUrl + '/api/Permission/UpsertPermissionDetail',
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token.access_token
                },
                body: JSON.stringify(permission)
            }
        ).then(res => res.json).then(response => {
            if (cb) cb();
        })
    }

    formatDate = formatDate;
    JSONpropToCamelCase = JSONpropToCamelCase;
}

// export const api = new API();
const apiinstance = new API()
const globalAny: any = global;
globalAny.api = apiinstance

export const api = apiinstance



export interface IoTTokenDecoded {
    nbf: number
    exp: number
    iss: string
    aud: string
    client_id: string
    role: Role[],
    sub: string
    auth_time: number
    idp: string
    tenant_id: string
    permissions: string[]
    'AspNet.Identity.SecurityStamp': string
    preferred_username: string
    email: string
    email_verified: boolean
    scope: string[]
    amr: string[]
}

export type Role = "ADMINISTRATOR" |
    "FULL_ACCESS" |
    "MANAGE_USERS" |
    "MANAGE_ENTITY_ACCESS_GROUPS" |
    "MANAGE_ENTITY_ACCESS" |
    "MANAGE_ENTITIES" |
    "MANAGE_DASHBOARD_VIEWS" |
    "DASHBOARD_OPERATOR" |
    "MANAGE_CALENDAR_EVENTS" |
    "MANAGE_GATEWAYS" |
    "VIEW_GATEWAY_HEALTH" |
    "VIEW_BILLING" |
    "VIEW_AUDIT_LOGS" |
    "REGISTER_GATEWAYS" |
    " MANAGE_DIGITAL_TWINS" |
    "MANAGE_POINT_OF_INTEREST" |
    "MANAGE_GATEWAY_DEPLOYMENT" |
    "MANAGE_DASHBOARD_CALENDAR_EVENTS" |
    "MANAGE_GATEWAY_HEALTH_THRESHOLDS" |
    "MANAGE_DASHBOARD_THEME" |
    "MANAGE_ICAL_INTEGRATION" |
    "BULK_ENTITY_MANAGEMENT" |
    "DEVELOPER_COMMUNITY" |
    "MANAGE_DEPLOYMENT" |
    "MANAGE_ALARMS" |
    "MANAGE_LIVESTREAM_VIDEO" |
    "MANAGE_MAPBOX" |
    "MANAGE_COMMANDER" |
    "MANAGE_OUTBOX_MAIL_SERVER" |
    "MANAGE_MICROSOFT_IOT_HUB" |
    "MANAGE_CLIENT_AUTHENTICATION_CONFIGURATION"


export function encodeQueryData(data: any) {
    const ret = [];
    for (const d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

export function generateDifficult(count: number) {
    const _sym = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
    let str = '';
    for (let i = 0; i < count; i++) {
        const tmp = _sym[Math.round(Math.random() * (_sym.length - 1))];
        str += '' + tmp;
    }
    return str;
}

export interface Token {
    access_token: string;
    expires_in: string;
    expires?: string;
    scope: string
    state: string
    token_type: string
}