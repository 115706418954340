import React from "react";
import { api } from "../api";
import styled from "styled-components";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { Button } from "./button";
import { CloudAppResponseDisplay, Table, TextButton, InputEdit, FormHeaderOptions, Header3 } from "./styledComponents";
import { isJson } from "../../shared/isJson";
import { CloseButtonDiv } from "./dropdownForm";
import { csvData } from "./csvExport";
import { CSVLink } from "react-csv";
import { csvLinkColor } from "./csvExport";

export interface Tag {
    businessGroup: string
    dataItemState: number
    dataString: string
    employeeCompany: string
    employeeName: string
    employeeNumber: string
    employeeSurname: string
    exceptions: string
    garmentSize: number
    garmentType: string
    issueDate: string
    issueType: string
    laundryId: string
    laundryName: string
    lineNumber: number
    shift: string
    site: string
    tagId: number
    user: string
    id: string
    changed?: boolean
}

const Panel = styled.div`
    background: #eee;
    flex: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    input {
        color: rgba(0,0,0,0.5);
        font-weight: 600;
    }
    
`;

interface FileForm {
    lastModified: number
    lastModifiedDate: Date
    name: string
    size: number
    type: string
}

interface State {
    response_uploadCsv?: CloudAppResponse<any>
    response_validateCsv?: CloudAppResponse<Tag[]>
    response_processFile?: CloudAppResponse<any>
    response_discardCsvFile?: CloudAppResponse<any>
    uploading: boolean
    file?: FileForm
    busy?: boolean

}
interface Props { }

export class TagsImportPage extends React.Component<Props, State> {
    state: State = {
        response_uploadCsv: undefined,
        response_validateCsv: undefined,
        response_processFile: undefined,
        response_discardCsvFile: undefined,
        uploading: false,
        file: undefined,
        busy: undefined
    }

    uploadTimeout : NodeJS.Timeout | undefined;
    fileInput : HTMLInputElement | undefined;
    csvheader = 'Site|LaundryName|LaundryID|EmpCompany|BusGroup|Empno|Surname|Garmenttype|tagid|Name|Shift|IssueType|IssueDate|Size';

    componentDidMount() {
        document.title = 'Gijima | Tags Import'
    }

    uploadCsv(e: any) {
        if (!api.token) throw new Error('token missing');

        this.setState({ uploading: true })
        const form = new FormData();
        form.append("file", e.target.files[0]);
        this.setState({ file: e.target.files[0] })

        this.uploadTimeout = setTimeout(() => {
            if (this.fileInput) this.fileInput.value = "";
            this.setState({ uploading: false, response_uploadCsv: { isSuccessful: false, exceptionMessage: 'Timeout', message: '' } })
        }, 30000)

        
        fetch(api.apiUrl + '/api/File/UploadCsv', {
            "headers": { Authorization: 'Bearer ' + api.token.access_token },
            method: "post",
            body: form
        }).then(res => res.text()).then((response_uploadCsvText) => {
            if (!isJson(response_uploadCsvText)) {
                // non json response..
                this.setState({
                    response_uploadCsv: {
                        isSuccessful: false,
                        message: response_uploadCsvText,
                        exceptionMessage: response_uploadCsvText
                    }, uploading: false
                });
                if (this.uploadTimeout) clearTimeout(this.uploadTimeout);
                return;
            }
            let response_uploadCsv: CloudAppResponse<any> = JSON.parse(response_uploadCsvText);

            if (this.fileInput) this.fileInput.value = "";
            if (this.uploadTimeout) clearTimeout(this.uploadTimeout);

            if (response_uploadCsv.isSuccessful === false) {
                this.processFile();
            }

            this.setState({ response_uploadCsv, uploading: false })
        }).catch(err => {
            if (this.uploadTimeout) clearTimeout(this.uploadTimeout);
            console.log(err);
        })
    }

    validateCsv() {
        if (!api.token) throw new Error('token missing');

        this.setState({busy: true})
        fetch(api.apiUrl + '/api/File/ValidateCsv',
            {
                method: 'post',
                headers: { Authorization: 'Bearer ' + api.token.access_token }
            })
            .then(res => res.json()).then((response_validateCsv: CloudAppResponse<any>) => {
                if (response_validateCsv.isSuccessful === true) {
                    this.setState({busy: false})
                }
                this.setState({ response_validateCsv, busy: false })
            }).catch(err => {
                console.log(err);
            });
    }

    processFile() {
        if (!api.token) throw new Error('token missing');

        this.setState({ response_processFile: undefined });

        fetch(api.apiUrl + '/api/File/ProcessFile',
            {
                method: 'post',
                headers: { Authorization: 'Bearer ' + api.token.access_token }
            })
            .then(res => res.text()).then((response) => {
                if (isJson(response)) {
                    this.setState({ response_processFile: JSON.parse(response) })
                } else {
                    console.log(response);
                }
            }).catch(err => {
                console.log('ProcessFile Error', err);
            });

    }

    discardCsvFile() {
        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/File/DiscardCsvFile',
            {
                method: 'delete',
                headers: { Authorization: 'Bearer ' + api.token.access_token }
            })
            .then(res => res.text()).then((response) => {
                if (isJson(response)) {
                    this.setState({ response_discardCsvFile: JSON.parse(response) })
                }
            }).catch(err => {
                console.log('DiscardCsvFile Error', err);
                // this.setState({ response_processFile: { isSuccessful: false, exceptionMessage: err, message: 'error' } })
            });
    }



    updateAndValidateRow(row: FileUploadModel, cb: (response: CloudAppResponse<Tag[]>) => void) {
        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/File/UpdateAndValidateRow',
            {
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + api.token.access_token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(row)
            })
            .then(res => res.text()).then((response) => {
                if (isJson(response)) {
                    cb(JSON.parse(response));
                }
            }).catch(err => {
                console.log('DiscardCsvFile Error', err);
                // this.setState({ response_processFile: { isSuccessful: false, exceptionMessage: err, message: 'error' } })
            });
    }

    deleteRow(row: FileUploadModel, cb: (response: CloudAppResponse<Tag[]>) => void) {
        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/File/DeleteRow',
            {
                method: 'delete',
                headers: {
                    Authorization: 'Bearer ' + api.token.access_token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(row)
            })
            .then(res => res.text()).then((response) => {
                if (isJson(response)) {
                    cb(JSON.parse(response));
                }
            }).catch(err => {
                console.log('DeleteRow Error', err);
            });
    }

    render() {

        let csvheaderServerReturn = 'Site|LaundryName|LaundryId|EmployeeCompany|BusinessGroup|EmployeeNumber|EmployeeSurname|GarmentType|TagId|EmployeeName|Shift|IssueType|IssueDate|GarmentSize';

        return <div >
            <h1>Import Tags</h1>

            <div style={{ display: 'flex', flexDirection: 'column', background: 'white' }}>

                <div style={{ padding: '20px' }}>
                    <div style={{
                        border: '2px solid rgba(0,0,0,0.1)',
                        borderRadius: 2
                    }}>
                        <Panel style={{ background: 'none' }}>
                            <div style={{ padding: 0, display: 'flex', flexDirection: 'row', }}>
                                <div style={{ paddingTop: 0, flex: 1, padding: 20 }}>
                                    <Header3 style={{ marginTop: 0, padding: 0, marginBottom: 10 }}>Upload .csv</Header3>

                                    <input type="file" name="file"
                                        ref={ref => {if (ref) this.fileInput = ref}}
                                        onChange={(e) => { this.uploadCsv(e); }} />

                                    <CloudAppResponseDisplay text='Upload' data={this.state.response_uploadCsv} />

                                    {(this.state.uploading) && <div style={{
                                        height: 50,
                                        padding: 10,
                                        textAlign: 'center'
                                    }}><div className="fa-2x"><i className="fas fa-sync fa-spin" /></div></div>}
                                </div>

                                <div style={{ background: '#eee', padding: 44, flex: 2 }}>
                                    <p>Example :</p>
                                    <pre>
                                        site|LaundryName|LaundryID|EmpCompany|BusGroup|Empno|Surname|Garmenttype|tagid|Name|Shift|IssueType|IssueDate|Size<br />
                                        NC|NC LD1|F DUBE|AMSA|BATT HEAT|X9838|DUBE|OVERALL JACKET|16080116|F|J3A|INITIAL|2020/01/01|34<br />
                                        NC|NC LD2|F DUBE|AMSA|BATT HEAT|X9838|DUBE|OVERALL JACKET|11627754|F|J3A|INITIAL|2020/01/01|34<br />
                                        NC|NC LD3|F DUBE|AMSA|BATT HEAT|X9838|DUBE|OVERALL JACKET|11919763|F|J3A|INITIAL|2020/01/01|30<br />
                                        NC|NC LD4|F DUBE|AMSA|BATT HEAT|X9838|DUBE|OVERALL JACKET|11963945|F|J3A|INITIAL|2020/01/01|28<br />
                                    </pre>
                                    <div><p>A .csv file can be exported from excel or from a plain text editor. The first row should
                                    have the column names as displayed below. Each row after that should have the exact
                                        same number of columns seperated by the pipe character.</p>
                                    </div>

                                    {/** EXPORT CSV */}
                                    
                                    <div>
                                        <Button icon='fal fa-download'>
                                            <CSVLink data={csvData} target="_blank" filename='Upload_Tags' style={csvLinkColor}>Download Template</CSVLink>
                                        </Button>
                                    </div>

                                </div>


                            </div>



                            {(true) && <div style={{ padding: 0 }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderTop: '2px solid rgba(0,0,0,0.1)'
                                }}>
                                    <div style={{
                                        flex: 1,
                                        padding: 20,
                                        borderRight: '2px solid rgba(0,0,0,0.1)'
                                    }}>
                                        <Button text='Validate Csv'
                                            onClick={() => { this.validateCsv() }}
                                            icon='fal fa-check' />
                                        <div style={{ paddingTop: 10 }}><CloudAppResponseDisplay text='Validate' data={this.state.response_validateCsv} /></div>
                                        {(this.state.busy) && <div><i className="fas fa-circle-notch fa-spin" /> Busy</div>}
                                    </div>

                                    <div style={{
                                        flex: 1,
                                        padding: 20,
                                        borderRight: '2px solid rgba(0,0,0,0.1)'
                                    }}>
                                        <Button text='Process File'
                                            onClick={() => { this.processFile() }}
                                            icon='fal fa-cog' />
                                        <div style={{ paddingTop: 10 }}><CloudAppResponseDisplay text='Process ' data={this.state.response_processFile} /></div>
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        padding: 20,
                                        borderRight: '2px solid rgba(0,0,0,0.1)'
                                    }}>
                                        <Button text='Discard CSV File'
                                            onClick={() => {
                                                this.setState({
                                                    response_discardCsvFile: undefined,
                                                    response_processFile: undefined,
                                                    response_uploadCsv: undefined,
                                                    response_validateCsv: undefined,
                                                    uploading: false,
                                                    file: undefined
                                                })
                                                this.discardCsvFile();
                                            }}
                                            icon='fal fa-times' />
                                        <div style={{ paddingTop: 10 }}><CloudAppResponseDisplay text='Discard ' data={this.state.response_discardCsvFile} /></div>
                                    </div>
                                </div>
                            </div>}


                            {(this.state.file) && <div style={{ flex: 1, padding: 0, borderTop: '2px solid rgba(0,0,0,0.1)' }}>
                                <div style={{ padding: 20 }}>
                                    {(this.state.file) && <div>
                                        File Size : {this.state.file.size}
                                    </div>}
                                    {(this.state.response_validateCsv) && <div>Row count: {(this.state.response_validateCsv)
                                        && (this.state.response_validateCsv.data) && this.state.response_validateCsv.data.length}</div>}
                                </div>
                            </div>}

                        </Panel>
                    </div>
                </div>



                {/* Table start */}
                {(this.state.response_validateCsv) && (this.state.response_validateCsv.data) && <div style={{ padding: 0 }}>
                    <Panel>
                        <FormHeaderOptions>
                            <div style={{ flex: 0, padding: 14, whiteSpace: 'nowrap' }}>Data Preview</div>

                            <div style={{ flex: 0, padding: 14, whiteSpace: 'nowrap' }}>
                                {(true) && <div></div>}
                                {(this.state.response_validateCsv.data.filter(tag => (tag.exceptions)).length > 0)
                                    ? <div style={{ color: 'red' }}>{this.state.response_validateCsv.data.filter(tag => (tag.exceptions)).length} Errors</div>
                                    : <div style={{ color: 'green' }}>No errors.</div>}
                            </div>

                            <div style={{ flex: 1 }} />
                            <div style={{ flex: 0, paddingTop: 7, whiteSpace: 'nowrap' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                                        <CloudAppResponseDisplay text='Process ' data={this.state.response_processFile} /></div>
                                    <Button text='Process File'
                                        onClick={() => { this.processFile() }}
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        icon='fal fa-cog' />
                                </div>
                            </div>
                            <CloseButtonDiv
                                style={{ background: 'rgba(150,0,0,0.25)' }}
                                onClick={(e) => {
                                    this.setState({
                                        response_discardCsvFile: undefined,
                                        response_processFile: undefined,
                                        response_uploadCsv: undefined,
                                        response_validateCsv: undefined,
                                        uploading: false,
                                        file: undefined
                                    })
                                    this.discardCsvFile();
                                }}><i className='fal fa-times' /></CloseButtonDiv>
                        </FormHeaderOptions>

                        {/* {(this.state.response_processFile) && <div
                        style={{ color: 'green', padding: 50, textAlign: 'center', fontSize: 30 }}>
                        <i className='fal fa-check-circle' style={{ fontSize: 70 }} /> <br /> {this.state.response_processFile.message}
                    </div>} */}


                        {(true) && <div style={{ overflowX: 'scroll' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <td></td>
                                        {(this.csvheader).split('|').map((heading, headingkey) => {
                                            return <td key={headingkey}>{heading}</td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.response_validateCsv.data.map((tag: Tag, tagkey) => {
                                        let display = tag.dataString.split('|');

                                        if (tag.exceptions !== '') {
                                            const background = 'rgba(218,100,105,0.2)'
                                            const styleTopRow = {
                                                padding: '0 0px 0 0',
                                                borderTop: '0px solid red',
                                                borderBottom: 'none',
                                                background
                                            }
                                            const styleBottomRow = {
                                                padding: '0 1px 0 0',
                                                borderBottom: '0px solid red',
                                                borderTop: 'none',
                                                color: 'rgba(218,100,105,1)', background
                                            }


                                            if (tag.exceptions === 'Column Count is not correct') {
                                                return <>
                                                    <tr key={tagkey}>
                                                        <td style={styleTopRow}>
                                                            <i className='fas fa-exclamation-triangle'
                                                                style={{
                                                                    marginLeft: 5,
                                                                    marginRight: 5,
                                                                    fontSize: 14,
                                                                    color: 'rgba(218,20,0,0.7)'
                                                                }} title={tag.exceptions} /></td>
                                                        <td style={{ ...styleTopRow, ...{ padding: 2 } }} colSpan={14}>
                                                            <input value={tag.dataString}
                                                                style={{ width: '100%', margin: '5px 0px', fontSize: 11 }}
                                                                onChange={(e) => {
                                                                    tag.dataString = e.target.value;
                                                                    tag.changed = true;
                                                                    this.setState({ response_validateCsv: this.state.response_validateCsv })
                                                                }}
                                                            />
                                                        </td>

                                                        <td style={styleTopRow} >

                                                            {(tag.changed) && <TextButton
                                                                style={{ marginRight: 5, marginLeft: 5 }}
                                                                onClick={() => {
                                                                    this.updateAndValidateRow({
                                                                        site: tag.dataString.split('|')[0],
                                                                        laundryName: tag.dataString.split('|')[1],
                                                                        laundryId: tag.dataString.split('|')[2],
                                                                        employeeCompany: tag.dataString.split('|')[3],
                                                                        businessGroup: tag.dataString.split('|')[4],
                                                                        employeeNumber: tag.dataString.split('|')[5],
                                                                        employeeSurname: tag.dataString.split('|')[6],
                                                                        garmentType: tag.dataString.split('|')[7],
                                                                        tagId: parseInt(tag.dataString.split('|')[8], 10),
                                                                        employeeName: tag.dataString.split('|')[9],
                                                                        shift: tag.dataString.split('|')[10],
                                                                        issueType: tag.dataString.split('|')[11],
                                                                        issueDate: tag.dataString.split('|')[12],
                                                                        garmentSize: parseInt(tag.dataString.split('|')[13], 10),
                                                                        dataString: tag.dataString,
                                                                        lineNumber: tag.lineNumber,
                                                                        id: tag.id
                                                                    }, (response) => {

                                                                        if (response.data && response.data[0]) {
                                                                            tag = response.data[0];
                                                                            this.setState({ response_validateCsv: response });
                                                                        }
                                                                    })
                                                                }}
                                                            ><i className='fad fa-save' /> Save</TextButton>}
                                                        </td>

                                                    </tr>
                                                    <tr key={tagkey + 'exceptionRow'}>
                                                        <td colSpan={99}
                                                            style={styleBottomRow}>
                                                            <div style={{ padding: 5, color: 'rgba(225,0,0,0.8)' }}>{tag.exceptions}</div>
                                                        </td>
                                                    </tr>
                                                </>
                                            }

                                            return (<>
                                                <tr key={tagkey + 'test'}>
                                                    <td style={styleTopRow} key={'exceptionCol'}>
                                                        {(tag.exceptions !== '') && <i className='fas fa-exclamation-triangle'
                                                            style={{
                                                                marginLeft: 5,
                                                                marginRight: 5,
                                                                fontSize: 14,
                                                                color: 'rgba(218,20,0,0.7)'
                                                            }} title={tag.exceptions} />}</td>

                                                    {(csvheaderServerReturn).split('|').map((heading, headingKey) => {
                                                        const value = tag.dataString.split('|')[headingKey];

                                                        return <td style={{ ...styleTopRow, ...{ padding: 2 } }} key={headingKey}>
                                                            <InputEdit
                                                                value={value}

                                                                onChange={e => {
                                                                    let editedValue = e.target.value; 
                                                                    if (heading.toLowerCase() === 'tagid') { editedValue = parseInt(e.target.value, 10).toString(); }
                                                                    if (heading.toLowerCase() === 'garmentsize') { editedValue = parseInt(e.target.value, 10).toString(); }
                                                                    
                                                                    let dataString = tag.dataString.split('|');

                                                                    const index = csvheaderServerReturn.split('|').findIndex(x=> x === heading);
                                                                    dataString[index] = editedValue;

                                                                    tag.dataString = dataString.join('|');
                                                                    tag.changed = true;

                                                                    this.setState({ response_validateCsv: this.state.response_validateCsv })
                                                                }}
                                                                style={{ padding: '3px 7px', fontSize: 11, width: '100%', border: '1px solid ' + background }} /></td>
                                                    })}
                                                    <td style={styleTopRow} key={tagkey + 'buttonCol'} >

                                                        {(tag.changed) && <TextButton
                                                            style={{ marginRight: 5, marginLeft: 5 }}
                                                            onClick={() => {
                                                                this.updateAndValidateRow({
                                                                    site: tag.site,
                                                                    shift: tag.shift,
                                                                    laundryName: tag.laundryName,
                                                                    laundryId: tag.laundryId,
                                                                    businessGroup: tag.businessGroup,
                                                                    employeeCompany: tag.employeeCompany,
                                                                    employeeNumber: tag.employeeNumber,
                                                                    employeeName: tag.employeeName,
                                                                    employeeSurname: tag.employeeSurname,
                                                                    tagId: tag.tagId,
                                                                    garmentType: tag.garmentType,
                                                                    garmentSize: tag.garmentSize,
                                                                    issueDate: tag.issueDate,
                                                                    issueType: tag.issueType,
                                                                    dataString: tag.dataString,
                                                                    lineNumber: tag.lineNumber,
                                                                    id: tag.id,
                                                                    user: tag.user
                                                                }, (response) => {
                                                                    if (response.data && response.data[0]) {
                                                                        tag = response.data[0];
                                                                        this.setState({ response_validateCsv: response });
                                                                    }
                                                                })
                                                            }}
                                                        ><i className='fad fa-save' /> Save</TextButton>}

                                                        {(!tag.changed && tag.exceptions) &&
                                                            <TextButton
                                                                style={{ marginRight: 5, marginLeft: 5 }}
                                                                onClick={() => {
                                                                    this.deleteRow({
                                                                        site: tag.site,
                                                                        shift: tag.shift,
                                                                        laundryName: tag.laundryName,
                                                                        laundryId: tag.laundryId,
                                                                        businessGroup: tag.businessGroup,
                                                                        employeeCompany: tag.employeeCompany,
                                                                        employeeNumber: tag.employeeNumber,
                                                                        employeeName: tag.employeeName,
                                                                        employeeSurname: tag.employeeSurname,
                                                                        tagId: tag.tagId,
                                                                        garmentType: tag.garmentType,
                                                                        garmentSize: tag.garmentSize,
                                                                        issueDate: tag.issueDate,
                                                                        issueType: tag.issueType,
                                                                        dataString: tag.dataString,
                                                                        lineNumber: tag.lineNumber,
                                                                        id: tag.id,
                                                                        user: tag.user
                                                                    }, (response) => {
                                                                        if (response.isSuccessful) {

                                                                            if (!this.state.response_validateCsv) return;
                                                                            if (!this.state.response_validateCsv.data) return;

                                                                            const response_validateCsv = this.state.response_validateCsv;
                                                                            if (response_validateCsv.data) response_validateCsv.data = response_validateCsv.data.filter( o => o !== tag );                                                                            
                                                                            this.setState({ response_validateCsv: this.state.response_validateCsv });
                                                                        }
                                                                    })
                                                                }}
                                                            ><i className='fad fa-trash' /> Delete</TextButton>
                                                        }

                                                    </td>
                                                </tr>
                                                {(tag.exceptions) && <tr key={tagkey + 'exceptionRow'}>
                                                    <td colSpan={99}
                                                        style={styleBottomRow}>
                                                        <div style={{ padding: 5, color: 'rgba(225,0,0,0.8)' }}>{tag.exceptions}</div>
                                                    </td>
                                                </tr>}


                                            </>)
                                        } else {
                                            return <tr key={tagkey + 'asdf'}>
                                                <td>{(tag.exceptions !== '') && <i className='fas fa-exclamation-triangle' style={{
                                                    fontSize: 14,
                                                    color: 'rgba(218,20,0,0.7)'
                                                }} title={tag.exceptions} />}</td>

                                                {(this.csvheader).split('|').map((heading, j) => {
                                                    return <td key={j + 'col'}>{display[j]}</td>
                                                })}
                                            </tr>
                                        }


                                    })}
                                </tbody>
                            </Table>
                        </div>}

                    </Panel>
                </div>}

                {/* Table end */}

            </div>

        </div>
    }
}

type DataItemStateinteger = number; // 0,1,2
type DateString = string;

export interface FileUploadModel {
    dataItemState?: DataItemStateinteger
    user?: string
    site?: string
    shift?: string
    laundryName?: string
    laundryId?: string
    businessGroup?: string
    employeeCompany?: string
    employeeNumber?: string
    employeeName?: string
    employeeSurname?: string
    tagId?: number
    garmentType?: string
    garmentSize?: number
    issueDate?: DateString
    issueType?: string
    exceptions?: string
    dataString?: string
    lineNumber: number
    id?: string
}