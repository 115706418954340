import React from "react";
import { Document, Page } from 'react-pdf'
import { Button } from "./button";

interface State {
    numPages?:number;
    pageNumber: number;
    width: number;
}

export class HowToUsePage extends React.Component {
    state :State = {
        numPages: undefined,
        pageNumber: 1,
        width: 100
    }

    rtime: Date | undefined;
    timeout: NodeJS.Timeout | undefined;
    delta = 200;

    el : HTMLDivElement | undefined;

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
        if (this.timeout) clearTimeout(this.timeout);
        document.title = 'Gijima | How to use'
    }

    resize = () => {
        this.rtime = new Date();
        if (this.timeout) { clearTimeout(this.timeout); }
        this.timeout = setTimeout(this.resizeEnd, 200);
    }

    resizeEnd = () => {
        console.log('Resize End run..')
        console.log('updateSize');
        if (this.el) if (this.state.width !== this.el.clientWidth) {
            this.setState({ width: this.el.clientWidth })
        }
    }

    render() {
        const { pageNumber, numPages } = this.state;

        return (
            <div ref={el => { if (el) this.el = el; this.resize(); }} >
                <Document
                    file="AMSA-RFID-User-Manual.pdf"
                    onLoadSuccess={(pdf)=>{
                        this.setState({ numPages : pdf.numPages });
                    }}
                >
                    <Page pageNumber={pageNumber} width={this.state.width} />
                </Document>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <Button
                            style={{ background: "rgba(255,255,255,0.1)" }}
                            icon='fal fa-arrow-left'
                            onClick={() => {
                                if (pageNumber > 1) {
                                    this.setState({ pageNumber: this.state.pageNumber - 1 })
                                }

                            }}
                            text='prev' />
                    </div>
                    <div>
                        <Button
                            style={{ background: "rgba(255,255,255,0.1)" }}
                            icon='fal fa-arrow-right'
                            onClick={() => {

                                if (!numPages) return;

                                if (pageNumber < numPages) {
                                    this.setState({ pageNumber: this.state.pageNumber + 1 })
                                }

                            }}
                            text='next' />
                    </div>
                    <div style={{ padding: 10 }}>Page {pageNumber} of {numPages}</div>
                </div>


                <h2 style={{ fontWeight: 600, color: 'white', marginTop: 50 }}>Download</h2>
                <div style={{ marginBottom: 50 }}>
                    <a target='_blank' href='/AMSA-RFID-User-Manual.pdf' style={{ color: 'white' }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            width: 300,
                            background: 'rgba(255,255,255,0.2)',
                            padding: 10,
                            borderRadius: 5
                        }}>
                            <div>
                                <i className='fas fa-file-pdf' style={{ color: 'white', fontSize: 50 }} />
                            </div>
                            <div style={{ paddingTop: 7, paddingLeft: 12 }}>AMSA RFID User Manual</div>
                        </div>
                    </a>
                </div>

            </div >
        );

    }
}