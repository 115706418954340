import React from "react"
// import { DropdownForm } from "./dropdownForm"
import { Table, InputEdit, TextButton, CloudAppResponseDisplay } from "./styledComponents"
import { Laundry } from "./dropdowns"
import { clone } from "../../shared/lodash_alt";
import { api } from "../api";
import { CloudAppResponse } from "../../shared/cloudappresponse";

interface State { }
interface Props {
    laundries?: Laundry[]
    onChange: (laundries: Laundry[]) => void
}

interface LaundryRequest {
    laundryId: string
    laundryName: string
}

interface LaundryEntityIdRequest {
    laundryId: string
    entityIdIn: string
    entityIdOut: string
}

export class LaundryPage extends React.Component<Props, State> {

    /** rename laundry name */
    UpsertLaundry(
        laundryRequest: LaundryRequest,
        cb: (response: CloudAppResponse<any>) => void
    ) {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/DropDowns/UpsertLaundry',
            {
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + api.token.access_token,
                    'Content-Type': 'application/json-patch+json'
                },
                body: JSON.stringify(laundryRequest)
            })
            .then(res => res.json()).then((response: CloudAppResponse<any>) => {
                cb(response);
            }).catch(err => { console.log(err) });
    }

    /** add/modify entityIdIn or entityIdOut on a laundry */
    UpsertLaundryEntityIds(
        laundryEntityIdRequest: LaundryEntityIdRequest,
        cb: (response: CloudAppResponse<any>) => void
    ) {
        if (!api.token) throw new Error('missing token');
        
        fetch(api.apiUrl + '/api/DropDowns/UpsertLaundryEntityIds',
            {
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + api.token.access_token,
                    'Content-Type': 'application/json-patch+json'
                },
                body: JSON.stringify(laundryEntityIdRequest)
            })
            .then(res => res.json()).then((response: CloudAppResponse<any>) => {
                cb(response);
            }).catch(err => { console.log(err) });
    }

    render() {

        if (!this.props.laundries) return <div>missing data...</div>

        let laundries: Laundry[] = clone(this.props.laundries);
        return <Table>
            <thead>
                <tr>
                    <td>id</td>
                    <td>Name</td>
                    <td>EntityIdIn</td>
                    <td>EntityIdOut</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {laundries.map((laundry, key) => {
                    if (laundry.entityIdIn === null) { laundry.entityIdIn = '' }
                    if (laundry.entityIdOut === null) { laundry.entityIdOut = '' }

                    return <tr key={key}>
                        <td>{laundry.id}</td>
                        <td>
                            <InputEdit
                                type='text'
                                value={laundry.name}
                                style={(laundry.changedName) ? { background: 'rgba(0,100,100,0.05)' } : {}}
                                onChange={(e) => {
                                    laundry.name = e.target.value;
                                    laundry.changedName = true;
                                    this.props.onChange(laundries);
                                }}
                            />

                        </td>
                        <td>
                            <InputEdit
                                type='text'
                                value={laundry.entityIdIn}
                                style={(laundry.changedEntityIds) ? { background: 'rgba(0,100,100,0.05)' } : {}}
                                onChange={(e) => {
                                    laundry.entityIdIn = e.target.value;
                                    laundry.changedEntityIds = true;
                                    this.props.onChange(laundries);
                                }}
                            />
                        </td>
                        <td>
                            <InputEdit
                                type='text'
                                value={laundry.entityIdOut}
                                style={(laundry.changedEntityIds) ? { background: 'rgba(0,100,100,0.05)' } : {}}
                                onChange={(e) => {
                                    laundry.entityIdOut = e.target.value;
                                    laundry.changedEntityIds = true;
                                    this.props.onChange(laundries);
                                }}
                            />
                        </td>
                        <td>{(laundry.changedName || laundry.changedEntityIds) && <TextButton
                            onClick={(e) => {

                                // changed Name
                                if (laundry.changedName) {
                                    this.UpsertLaundry({ laundryId: laundry.id, laundryName: laundry.name },
                                        (response) => {

                                            laundry.response = response;
                                            if (!response.isSuccessful) {
                                                this.props.onChange(laundries);
                                            }

                                            if (response.isSuccessful) {
                                                delete laundry.changedName;
                                                this.props.onChange(laundries);
                                                return;
                                            }
                                        })
                                }


                                // changed EntityIds
                                if (laundry.changedEntityIds) {
                                    this.UpsertLaundryEntityIds({
                                        laundryId: laundry.id,
                                        entityIdIn: laundry.entityIdIn,
                                        entityIdOut: laundry.entityIdOut
                                    }, (response) => {
                                        laundry.response = response;
                                        if (!response.isSuccessful) {
                                            this.props.onChange(laundries);
                                        }
                                        if (response.isSuccessful) {
                                            delete laundry.changedEntityIds;
                                            this.props.onChange(laundries);
                                        }
                                    })
                                }

                            }}
                        ><i className='fad fa-save' /> Save</TextButton>}

                            <CloudAppResponseDisplay data={laundry.response} />
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    }
}