import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../theme';
import { Menu, MenuData } from './menu';

// tslint:disable-next-line: no-empty-interface
interface Props { menu: MenuData[] }

// tslint:disable-next-line: no-empty-interface
interface State { }

const NavBarBlock = styled.div`
    border-top: 8px solid #d81a2b;
    background: #1d2029;
`;

const NavBarWrap = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
    white-space: nowrap;
    padding: 10px;
    
`;

export class NavBar extends React.Component<Props, State> {
    state = {
        path: window.location.pathname
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({ path: window.location.pathname })
        }, 100)
    }

    /** this fires just before the router, so we use a slight delay to update path and thus active classes on buttons */
    updatePath = () => {
        setTimeout(() => {
            this.setState({ path: window.location.pathname })
        }, 50)
    }

    render() {
        return (
            <NavBarBlock>
                <Container>
                    <NavBarWrap>
                        <Menu data={this.props.menu} />
                    </NavBarWrap>
                </Container>
            </NavBarBlock>
        )
    }

}
