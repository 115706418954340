// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore

export function clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}


export function filter(obj: any, func: any) {
    return obj.filter(func);
}

export function sortBy(obj: any, func: any) {
    return obj.concat().sort(func);
}

export function recursiveFlat(inObj: any) {
    const res: any = [];

    (function recurse(obj, current?: any) {

        if (Array.isArray(obj)) { return; }

        for (const key in obj) {
            if (key) {
                const value = obj[key];
                const newKey = current ? current + '.' + key : key; // joined key with dot
                if (value && typeof value === 'object') {
                    res.push(newKey);
                    recurse(value, newKey); // it's a nested object, so do it again
                } else {
                    res.push(newKey); // it's not an object, so set the property
                }
            }
        }
    })(inObj);

    return res;
}

export function recursiveFlatOrig(inObj: any) {
    const res: any = {};

    (function recurse(obj, current?: any) {
        for (const key in obj) {
            if (key) {
                const value = obj[key];
                const newKey = current ? current + '.' + key : key; // joined key with dot
                if (value && typeof value === 'object') {
                    recurse(value, newKey); // it's a nested object, so do it again
                } else {
                    res[newKey] = value; // it's not an object, so set the property
                }
            }
        }
    })(inObj);

    return res;
}