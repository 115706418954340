
export  const csvData = [
    ["site ", " LaundryName ", " LaundryID ", " EmpCompany ", " BusGroup ", " Empno ", " Surname ", " Garmenttype ", " tagid ", " Name ", " Shift ", " IssueType ", " IssueDate ", " Size "],
    ["NC ", " NC LD1 ", " F DUBE ", " AMSA ", " BATT HEAT ", " X9838 ", " DUBE ", " OVERALL JACKET ", " 16080116 ", " F ", " J3A ", " INITIAL ", " 2020 / 01 / 01 ", " 34 "],
    ["NC ", " NC LD2 ", " F DUBE ", " AMSA ", " BATT HEAT ", " X9838 ", " DUBE ", " OVERALL JACKET ", " 11627754 ", " F ", " J3A ", " INITIAL ", " 2020 / 01 / 01 ", " 34 "],
    ["NC ", " NC LD3 ", " F DUBE ", " AMSA ", " BATT HEAT ", " X9838 ", " DUBE ", " OVERALL JACKET ", " 11919763 ", " F ", " J3A ", " INITIAL ", " 2020 / 01 / 01 ", " 30 "]
];

export const  csvLinkColor = {
    color: '#fff'
}


