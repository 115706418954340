import {
    CustomThemeProvider,
    DynamicGrid,
    Theme,
} from '@iotnxt-dev/reactcomponents';

import React from 'react';
import { api } from '../api';
import {
    conditionalOverrides_ColouredStatus,
    conditionalOverrides_EmployeeNumber,
    conditionalOverrrides_FormatDate,
    conditionalRowStyle_ColoredStatus,
} from './dynamicGridOverrides';

interface Props {}
interface State {}

export class AllTags extends React.Component<Props, State> {
    state: any = {
        colourKeys: {
            in: api.theme ? api.theme.noAlertStatusColour : '#82d260',
            out: api.theme ? api.theme.highAlertStatusColour : '#e06568',
        },
    };
    render() {
        if (!api.token) return <div>loading...</div>;
        return (
            <CustomThemeProvider
                theme={Theme.defaultThemeData.themes['default']}
            >
                <h1>All Tags</h1>
                <DynamicGrid
                    pageSize={20}
                    columnControls={false}
                    useHistory={false}
                    allowEdits={false}
                    jsonPropertiesOverride={{
                        "enableEdit": true,
                        "enableUpload": true,
                        "orderQuery": "",
                        "columns": {
                          "employeeNumber": {
                            "isLocked": false,
                            "displayName": "Emp#",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 0
                          },
                          "tagId": {
                            "isLocked": false,
                            "displayName": "Tag ID",
                            "type": "String",
                            "values": [],
                            "isVisible": true,
                            "order": 0
                          },
                          "site": {
                            "isLocked": false,
                            "displayName": "Site",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 1
                          },
                          "employeeName": {
                            "isLocked": false,
                            "displayName": "Employee Name",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 2
                          },
                          "laundryId": {
                            "isLocked": false,
                            "displayName": "Laundry Id",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 3
                          },
                          "shift": {
                            "isLocked": false,
                            "displayName": "Shift",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 4
                          },
                          "businessGroup": {
                            "isLocked": false,
                            "displayName": "Business Group",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 5
                          },
                        //   "tagNumber": {
                        //     "isLocked": false,
                        //     "displayName": "Tag#",
                        //     "type": "string",
                        //     "values": [],
                        //     "isVisible": true,
                        //     "order": 6
                        //   },
                          "statusDate": {
                            "isLocked": false,
                            "displayName": "Status Date",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 7
                          },
                          "status": {
                            "isLocked": false,
                            "displayName": "Status",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 8
                          },
                          "garmentType": {
                            "isLocked": false,
                            "displayName": "Garment",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 9
                          },
                          "garmentSize": {
                            "isLocked": false,
                            "displayName": "Garment Size",
                            "type": "string",
                            "values": [],
                            "isVisible": true,
                            "order": 10
                          }
                        }
                      }}
                    orderByColumn="statusDate"
                    orderByColumnSorting="DESC"
                    autoRefresh={true}
                    useExport
                    showAutoRefreshToggle={true}
                    config={{
                        server: '',
                        apiPath: '/api/AllTagsDynamicGrid',
                        authorization: 'Bearer ' + api.token.access_token,
                    }}
                    conditionalOverrides={[
                        conditionalOverrides_EmployeeNumber,
                        conditionalOverrides_ColouredStatus,
                        conditionalOverrrides_FormatDate,
                    ]}
                    conditionalRowStyle={conditionalRowStyle_ColoredStatus}
                />
            </CustomThemeProvider>
        );
    }
}
