import React from "react";
import { api } from "../api";
import styled from "styled-components";
import { Button } from "./button";
import { DropdownForm } from "./dropdownForm";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { Permission } from "./permissions_page";
import { TagButton, ButtonSave, InputEdit, TinyButton, Options, Table, CloudAppResponseDisplay } from "./styledComponents";
import { InputSelect } from "./inputselect";
import { Loading } from "./loading";

export interface Role {
    id: string
    roleName: string
    permissions: string[]
    changed?: boolean
    addPermission?: boolean
}




const Input = styled.input`
    padding:10px;
    border: 1px solid rgba(0,0,0,0.25);
    width: 100%;
`;

interface Props { }
interface State {
    roles: Role[],
    permissions: Permission[],
    showAddRoleForm: boolean
    addRoleForm: Role,
    response_getAllRoles?: CloudAppResponse<any>
    response_addNewRole?: CloudAppResponse<any>
}

export class RolesPage extends React.Component<Props, State> {

    state: State = {
        roles: [],
        permissions: [],
        showAddRoleForm: false,
        addRoleForm: {
            id: '',
            roleName: '',
            permissions: []
        },
        response_getAllRoles: undefined,
        response_addNewRole: undefined
    }

    componentDidMount() {
        this.getAllRoles();
        document.title = 'Gijima | Manage Roles'
    }

    getAllRoles() {

        api.getAllPermissions(permissions => {
            this.setState({ permissions });
        })

        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Role/GetAllRoles',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json()).then((response: CloudAppResponse<Role[]>) => {
                if (response.isSuccessful && response.data) {
                    this.setState({ roles: response.data });
                } else {
                    this.setState({ roles: [], response_getAllRoles: response });
                }

            })
    }

    upsertRole(role: Role) {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Role/UpsertRoleDetail',
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + api.token.access_token
                },
                body: JSON.stringify(role)
            }
        ).then(res => res.json).then(response => {
            console.log(response);
        })
    }

    render() {
        return <div style={{ background: 'white' }}>
            {(this.state.showAddRoleForm) && <DropdownForm
                title='Add New Role'
                apply={() => {
                    if (!api.token) throw new Error('missing token');

                    this.setState({ response_addNewRole: undefined })
                    fetch(api.apiUrl + '/api/Role/UpsertRoleDetail', {
                        method: 'post', headers: {
                            Authorization: 'Bearer ' + api.token.access_token,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }, body: JSON.stringify(this.state.addRoleForm)
                    }).then(res => res.json()).then(response => {
                        this.setState({ response_addNewRole: response })
                        if (response.isSuccessful) {
                            this.setState({ showAddRoleForm: false })
                            this.getAllRoles();
                        }

                    })
                }}
                applyButtonIcon='fal fa-plus'
                applyButtonText='Add New Role'
                close={() => { this.setState({ showAddRoleForm: false }); }}
            >
                <div>
                    Role Name:<br />
                    <Input
                        type='text'
                        value={this.state.addRoleForm.roleName}
                        onChange={(e) => {
                            let addRoleForm = this.state.addRoleForm;
                            addRoleForm.roleName = e.target.value;
                            this.setState({ addRoleForm })
                        }}
                    />
                </div>
                <div style={{ paddingTop: 10 }}>
                    Permissions: <br />
                    <div>
                        {this.state.addRoleForm.permissions.map((permissionName, i) => {

                            return <TagButton
                                deleteClick={() => {
                                    console.log()
                                    let addRoleForm = this.state.addRoleForm;
                                    addRoleForm.permissions = addRoleForm.permissions.filter((per) => (per !== permissionName))
                                    this.setState({ addRoleForm })
                                }}
                                key={permissionName}>{permissionName}</TagButton>


                        })}
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <InputSelect
                            onChange={(e) => {
                                // check for double..
                                let addRoleForm = this.state.addRoleForm;
                                if (addRoleForm.permissions.indexOf(e.target.value) === -1) {
                                    addRoleForm.permissions.push(e.target.value);
                                    this.setState({ addRoleForm })
                                }

                            }}
                            placeholder='Add permission'
                            value=''
                            datalist={this.state.permissions.map((per) => { return { value: per.permissionName, text: per.permissionName } })} />
                    </div>
                </div>

                <div style={{ textAlign: 'right', paddingRight: 10 }}>
                    <CloudAppResponseDisplay data={this.state.response_addNewRole} />
                </div>
            </DropdownForm>}

            <Options>
                <div style={{ flex: 1 }} />

                <div style={{ paddingRight: 10 }}>
                    <Button text='Refresh'
                        onClick={() => {
                            this.setState({ roles: [], permissions: [] })
                            this.getAllRoles();
                        }}
                        style={{
                            color: 'rgba(0,0,0,0.25)',
                            background: 'rgba(0,0,0,0)',
                            border: '2px solid rgba(0,0,0,0)',
                            fontWeight: 600,
                            borderRadius: 3
                        }}
                        icon='fa fa-redo' />
                </div>

                <div>
                    <Button text='Add New Role'
                        onClick={() => {
                            this.setState({
                                response_addNewRole: undefined,
                                showAddRoleForm: true,
                                addRoleForm: {
                                    id: '',
                                    roleName: '',
                                    permissions: []
                                }
                            });
                        }}
                        style={{
                            background: '#0d3859',
                            borderRadius: 3
                        }}
                        icon='fal fa-plus' />
                </div>
            </Options>


            <Table style={{ padding: 0 }}>
                <thead>
                    <tr>
                        <td>id</td>
                        <td>Role Name</td>
                        <td>Permissions</td>
                        <td style={{ width: 80 }}></td>
                    </tr>
                </thead>

                {(this.state.roles === undefined || this.state.permissions === undefined) && <tbody><tr><td colSpan={99} style={{ textAlign: 'center' }} ><Loading /></td></tr></tbody>}

                {(this.state.response_getAllRoles) && <tbody><tr><td colSpan={99} style={{ textAlign: 'center' }}>
                    Error: {this.state.response_getAllRoles.exceptionMessage}
                </td></tr></tbody>}

                {(this.state.roles !== undefined && this.state.roles !== null && this.state.permissions !== undefined) && <tbody>
                    {(this.state.roles.length === 0) && <tr><td colSpan={99} style={{ textAlign: 'center' }}>No data to display.</td></tr>}
                    {(this.state.roles && this.state.permissions) && (this.state.roles.map((role, i) => {
                        return <tr key={i}>
                            <td>{role.id}</td>
                            <td style={{ width: 200 }}><InputEdit
                                onChange={e => {
                                    role.roleName = e.target.value;
                                    role.changed = true;
                                    this.setState({ roles: this.state.roles })
                                }}
                                value={role.roleName} /></td>
                            <td style={{ whiteSpace: 'normal' }}>{role.permissions.map((permissionName, i) => {


                                return <TagButton
                                    key={i}
                                    deleteClick={() => {
                                        role.permissions = role.permissions.filter((per) => (per !== permissionName))
                                        this.upsertRole(role);
                                        this.setState({ roles: this.state.roles })
                                    }}
                                >{permissionName}</TagButton>

                            })}

                                {(!role.addPermission) && <TinyButton icon='fas fa-plus' onClick={() => {
                                    for (var r of this.state.roles) {
                                        delete r.addPermission;
                                    }
                                    role.addPermission = true;
                                    this.setState({ roles: this.state.roles })
                                    this.upsertRole(role);
                                }} />}

                                {(role.addPermission) && <>
                                    <div style={{ display: 'inline-block', position: 'relative', zIndex: 10 }}>
                                        <InputSelect placeholder='Add permission'
                                            onChange={(e) => {
                                                // check for double..
                                                if (role.permissions.indexOf(e.target.value) === -1) {
                                                    role.permissions.push(e.target.value);


                                                    for (var r of this.state.roles) {
                                                        delete r.addPermission;
                                                    }

                                                    this.setState({ roles: this.state.roles })
                                                    this.upsertRole(role);
                                                }

                                            }}
                                            value=''
                                            datalist={this.state.permissions.map((per) => { return { value: per.permissionName, text: per.permissionName } })}
                                        />
                                    </div>
                                    <TinyButton icon='fas fa-check' style={{ marginLeft: 10, color: 'rgba(0,200,120,0.75)' }} onClick={() => {
                                        delete role.addPermission;
                                        this.setState({ roles: this.state.roles })
                                    }} />
                                </>
                                }

                            </td>
                            <td>
                                {(role.changed) && <ButtonSave onClick={() => {
                                    delete role.changed;
                                    this.upsertRole(role);
                                    this.setState({ roles: this.state.roles })
                                }} />}
                            </td>
                        </tr>
                    }))}

                </tbody>}
            </Table>
        </div >
    }
}