import React from "react";
import { DropdownsBlock } from "./dropdownsBlock";
import styled from "styled-components";
import { api } from "../api";
import { DropdownForm } from "./dropdownForm";
import { DropdownList } from "./dropdownList";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { InputEdit, CloudAppResponseDisplay } from "./styledComponents";
import { LaundryPage } from "./laundry";
import { constants } from "../../shared/appsettings";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
`

export interface Site {
    id: string
    name: string
}

export interface Shift {
    id: string
    name: string
}

export interface Company {
    id: string
    name: string
}

export interface BusinessGroup {
    id: string
    name: string
}

export interface Laundry {
    id: string
    name: string
    laundryIds: string[]
    entityIdIn: string
    entityIdOut: string
    /** is the user editing it, then show the save button */
    changedName?: boolean
    changedEntityIds?: boolean
    /** server response per entry */
    response?: CloudAppResponse<any>
}

export interface GarmentType {
    id: string
    name: string
}

interface State {
    sites?: Site[],
    shifts?: Shift[],
    companies?: Company[],
    businessGroups?: BusinessGroup[],
    laundries?: Laundry[],
    garmentTypes?: GarmentType[],
    // forms
    siteAdd: boolean,
    businessGroupAdd: boolean,
    laundryAdd: boolean,
    companyAdd: boolean,
    garmentTypeAdd: boolean,
    shiftAdd: boolean,
    // formContent
    formContent: any,
    // lists
    sitesList: boolean,
    shiftsList: boolean,
    companiesList: boolean,
    businessGroupsList: boolean,
    laundriesList: boolean,
    garmentTypesList: boolean
    // responses (timeout errors)
    response_getSites?: CloudAppResponse<any>
    response_getBusinessGroups?: CloudAppResponse<any>,
    response_getLaundries?: CloudAppResponse<any>,
    response_getCompanies?: CloudAppResponse<any>,
    response_getGarmentTypes?: CloudAppResponse<any>,
    response_getShifts?: CloudAppResponse<any>,

    response_siteAdd?: CloudAppResponse<any>
    response_businessGroupAdd?: CloudAppResponse<any>
    response_laundryAdd?: CloudAppResponse<any>
    response_companyAdd?: CloudAppResponse<any>
    response_garmentTypeAdd?: CloudAppResponse<any>
    response_shiftAdd?: CloudAppResponse<any>
}

interface Props {

}

export class DropdownsPage extends React.Component<Props, State> {

    state: State = {
        sites: undefined,
        shifts: undefined,
        companies: undefined,
        businessGroups: undefined,
        laundries: undefined,
        garmentTypes: undefined,
        // forms
        siteAdd: false,
        businessGroupAdd: false,
        laundryAdd: false,
        companyAdd: false,
        garmentTypeAdd: false,
        shiftAdd: false,
        // formContent
        formContent: '',
        // lists
        sitesList: false,
        shiftsList: false,
        companiesList: false,
        businessGroupsList: false,
        laundriesList: false,
        garmentTypesList: false,
        // responses (errors)
        response_siteAdd: undefined,
        response_businessGroupAdd: undefined,
        response_laundryAdd: undefined,
        response_companyAdd: undefined,
        response_garmentTypeAdd: undefined,
        response_shiftAdd: undefined,
        // timeouts on gets
        response_getSites: undefined,
        response_getBusinessGroups: undefined,
        response_getLaundries: undefined,
        response_getCompanies: undefined,
        response_getGarmentTypes: undefined,
        response_getShifts: undefined
    }

    // timeout handles
    timeout_getSites:NodeJS.Timeout | undefined;
    timeout_getBusinessGroups:NodeJS.Timeout | undefined;
    timeout_getLaundries:NodeJS.Timeout | undefined;
    timeout_getCompanies:NodeJS.Timeout | undefined;
    timeout_getGarmentTypes:NodeJS.Timeout | undefined;
    timeout_getShifts:NodeJS.Timeout | undefined;

    componentDidMount() {
        this.getData();
        document.title = 'Gijima | Set Up Dropdowns'
    }

    componentWillUnmount() {
        // forget about pending timeouts if we browse away
        if (this.timeout_getSites) clearTimeout(this.timeout_getSites);
        if (this.timeout_getBusinessGroups) clearTimeout(this.timeout_getBusinessGroups);
        if (this.timeout_getLaundries) clearTimeout(this.timeout_getLaundries);
        if (this.timeout_getCompanies) clearTimeout(this.timeout_getCompanies);
        if (this.timeout_getGarmentTypes) clearTimeout(this.timeout_getGarmentTypes);
        if (this.timeout_getShifts) clearTimeout(this.timeout_getShifts);
    }

    getData() {
        // https://gijima-api.qa.iotnxt.io/swagger/index.html
        this.getSites();
        this.getShifts();
        this.getCompanies();
        this.getBusinessGroups();
        this.getLaundries();
        this.getGarmentTypes();
    }

    // refactored for reuse
    getSites() { this.getWithTimeout(this.timeout_getSites, 'response_getSites', '/api/DropDowns/GetSites', 'sites'); }
    getShifts() { this.getWithTimeout(this.timeout_getShifts, 'response_getShifts', '/api/DropDowns/GetShifts', 'shifts'); }
    getCompanies() { this.getWithTimeout(this.timeout_getCompanies, 'response_getCompanies', '/api/DropDowns/GetCompanies', 'companies'); }
    getBusinessGroups() { this.getWithTimeout(this.timeout_getBusinessGroups, 'response_getBusinessGroups', '/api/DropDowns/GetBusinessGroups', 'businessGroups'); }
    getLaundries() { this.getWithTimeout(this.timeout_getLaundries, 'response_getLaundries', '/api/DropDowns/GetLaundries', 'laundries'); }
    getGarmentTypes() { this.getWithTimeout(this.timeout_getGarmentTypes, 'response_getGarmentTypes', '/api/DropDowns/GetGarmentTypes', 'garmentTypes'); }

    getWithTimeout(timeoutHandle: NodeJS.Timeout | undefined, response_timeout:string, apipath:string, stateprop:string) {
        // start a timeout incase fetch takes too long
        timeoutHandle = setTimeout(() => {
            let stateupdate: any = {}
            stateupdate[response_timeout] = { isSuccessful: false, message: 'Timeout' }
            this.setState(stateupdate)
            console.error(new Error('GET ' + apipath + ' timeout.'))
        }, constants.timeoutMs);

        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + apipath,
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json())
            .then((response: CloudAppResponse<any[]>) => {
                let stateResponse: any = {}
                
                stateResponse[stateprop] = response.data || [response];                
                stateResponse[response_timeout] = undefined;
                this.setState(stateResponse);
                if (timeoutHandle) clearTimeout(timeoutHandle);
            }).catch(err => {
                let stateError: any = {};
                stateError[stateprop] = [];
                stateError[response_timeout] = { isSuccessful: false, message: err.message };
                this.setState(stateError);
                if (timeoutHandle) clearTimeout(timeoutHandle);
            });
    }

    upsertData(
        url: string,
        body: any,
        cb: (response: CloudAppResponse<any>) => void): void {
        
            if (!api.token) throw new Error('missing token');

        fetch(url, {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + api.token.access_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json-patch+json'
            },
            body: JSON.stringify(body)
        }).then(response => response.json()).then((response: CloudAppResponse<any>) => {
            cb(response);
        }).catch(err => { cb(err) })
    }

    render() {
        return <div style={{
            background: 'white',
            maxWidth: 1000,
            margin: '0 auto',
            marginBottom: 50,
        }}>
            <div style={{ padding: 5, background: 'rgba(0,0,0,0.05)' }}>
                {/* =================== Forms ================== */}

                {(this.state.siteAdd) && <DropdownForm
                    title='Sites - Add New'
                    apply={() => {

                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertSite',
                            { siteId: '', siteName: this.state.formContent },
                            (response) => {
                                if (!response.isSuccessful) {
                                    this.setState({ response_siteAdd: response });
                                }
                                if (response.isSuccessful) {
                                    this.setState({
                                        siteAdd: false,
                                        formContent: '',
                                        response_siteAdd: undefined
                                    });
                                    this.getSites(); // updates list.
                                }
                            });

                    }}
                    close={() => { this.setState({ siteAdd: false, response_siteAdd: undefined }) }}>
                    Site Name:<br />
                    <InputEdit
                        placeholder='Type Site Name here...'
                        type='text'
                        value={this.state.formContent}
                        onChange={(e) => { this.setState({ formContent: e.target.value }) }} />
                    <CloudAppResponseDisplay data={this.state.response_siteAdd} />
                </DropdownForm>}


                {(this.state.businessGroupAdd) && <DropdownForm
                    title='Business Group - Add New'
                    apply={() => {
                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertBusinessGroup',
                            {
                                businessGroupId: '',
                                businessGroupName: this.state.formContent
                            },
                            (response) => {
                                if (!response.isSuccessful) {
                                    this.setState({ response_businessGroupAdd: response });
                                }
                                if (response.isSuccessful) {
                                    this.setState({
                                        businessGroupAdd: false,
                                        formContent: '',
                                        response_businessGroupAdd: undefined
                                    });
                                    this.getBusinessGroups(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ businessGroupAdd: false, response_businessGroupAdd: undefined }) }}>
                    Business Group Name:<br />
                    <InputEdit
                        placeholder='Type Business Group Name here...'
                        type='text'
                        value={this.state.formContent}
                        onChange={(e) => { this.setState({ formContent: e.target.value }) }} />
                    <CloudAppResponseDisplay data={this.state.response_businessGroupAdd} />
                </DropdownForm>}

                {(this.state.laundryAdd) && <DropdownForm
                    title='Laundry - Add New'
                    apply={() => {
                        this.upsertData(api.apiUrl + '/api/DropDowns/UpsertLaundry', {
                            laundryId: '',
                            laundryName: this.state.formContent
                        },
                            (response) => {
                                if (!response.isSuccessful) {
                                    this.setState({ response_laundryAdd: response });
                                }
                                if (response.isSuccessful) {
                                    this.setState({
                                        laundryAdd: false,
                                        formContent: '',
                                        response_laundryAdd: undefined
                                    });
                                    this.getLaundries(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ laundryAdd: false, response_laundryAdd: undefined }) }}>
                    Laundry Name:<br />
                    <InputEdit
                        placeholder='Type Laundry Name here...'
                        type='text'
                        value={this.state.formContent}
                        onChange={(e) => { this.setState({ formContent: e.target.value }) }} />
                    <CloudAppResponseDisplay data={this.state.response_laundryAdd} />
                </DropdownForm>}


                {(this.state.companyAdd) && <DropdownForm
                    title='Company - Add New'
                    apply={() => {
                        this.upsertData(api.apiUrl + '/api/DropDowns/UpsertCompany', {
                            companyId: '',
                            companyName: this.state.formContent
                        },
                            (response) => {
                                if (!response.isSuccessful) {
                                    this.setState({ response_companyAdd: response });
                                }
                                if (response.isSuccessful) {
                                    this.setState({
                                        companyAdd: false,
                                        formContent: '',
                                        response_companyAdd: undefined
                                    });
                                    this.getCompanies(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ companyAdd: false, response_companyAdd: undefined }) }}>
                    Company Name:<br />
                    <InputEdit
                        placeholder='Type Company Name here...'
                        type='text'
                        value={this.state.formContent}
                        onChange={(e) => { this.setState({ formContent: e.target.value }) }} />
                    <CloudAppResponseDisplay data={this.state.response_companyAdd} />
                </DropdownForm>}


                {(this.state.garmentTypeAdd) && <DropdownForm
                    title='Garment Type - Add New'
                    apply={() => {
                        this.upsertData(api.apiUrl + '/api/DropDowns/UpsertGarmentType', {
                            garmentTypeId: '',
                            garmentTypeName: this.state.formContent
                        },
                            (response) => {
                                if (!response.isSuccessful) {
                                    this.setState({ response_garmentTypeAdd: response });
                                }
                                if (response.isSuccessful) {
                                    this.setState({
                                        garmentTypeAdd: false,
                                        formContent: '',
                                        response_garmentTypeAdd: undefined
                                    });
                                    this.getGarmentTypes(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ garmentTypeAdd: false, response_garmentTypeAdd: undefined }) }}>
                    Garment Type Name:<br />
                    <InputEdit
                        placeholder='Type Garment Type Name here...'
                        type='text'
                        value={this.state.formContent}
                        onChange={(e) => { this.setState({ formContent: e.target.value }) }} />
                    <CloudAppResponseDisplay data={this.state.response_garmentTypeAdd} />
                </DropdownForm>}

                {(this.state.shiftAdd) && <DropdownForm
                    title='Shift - Add New'
                    apply={() => {
                        this.upsertData(api.apiUrl + '/api/DropDowns/UpsertShift', {
                            shiftId: '',
                            shiftName: this.state.formContent
                        },
                            (response) => {
                                if (!response.isSuccessful) {
                                    this.setState({ response_shiftAdd: response });
                                }
                                if (response.isSuccessful) {
                                    this.setState({
                                        shiftAdd: false,
                                        formContent: '',
                                        response_shiftAdd: undefined
                                    });
                                    this.getShifts(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ shiftAdd: false, response_shiftAdd: undefined }) }}>
                    Shift Name:<br />
                    <InputEdit
                        placeholder='Type Shift Name here...'
                        type='text'
                        value={this.state.formContent}
                        onChange={(e) => { this.setState({ formContent: e.target.value }) }} />
                    <CloudAppResponseDisplay data={this.state.response_shiftAdd} />
                </DropdownForm>}

                {/* ================================== Lists ===================== */}

                {(this.state.sitesList) && <DropdownList
                    list={this.state.sites}
                    title='Sites'
                    onChange={(sites) => { this.setState({ sites }) }}
                    apply={(body, cb) => {
                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertSite',
                            { siteId: body.id, siteName: body.name },
                            (response) => {
                                cb(response);
                                if (!response.isSuccessful) {
                                    console.error(response);
                                }
                                if (response.isSuccessful) {
                                    this.getSites(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ sitesList: false }) }} />}

                {(this.state.shiftsList) && <DropdownList
                    list={this.state.shifts}
                    title='Shifts'
                    onChange={(shifts) => { this.setState({ shifts }) }}
                    apply={(body, cb) => {
                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertShift',
                            { shiftId: body.id, shiftName: body.name },
                            (response) => {
                                cb(response);
                                if (!response.isSuccessful) {
                                    console.error(response);
                                }
                                if (response.isSuccessful) {
                                    this.setState({ formContent: '' });
                                    this.getShifts(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ shiftsList: false }) }} />}

                {(this.state.companiesList) && <DropdownList
                    list={this.state.companies}
                    title='Companies'
                    onChange={(companies) => { this.setState({ companies }) }}
                    apply={(body, cb) => {
                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertCompany',
                            { companyId: body.id, companyName: body.name },
                            (response) => {
                                cb(response);
                                if (!response.isSuccessful) {
                                    console.error(response);
                                }
                                if (response.isSuccessful) {
                                    this.setState({ formContent: '' });
                                    this.getCompanies(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ companiesList: false }) }} />}

                {(this.state.businessGroupsList) && <DropdownList
                    list={this.state.businessGroups}
                    title='Business Groups'
                    onChange={(businessGroups) => { this.setState({ businessGroups }) }}
                    apply={(body, cb) => {
                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertBusinessGroup',
                            { businessGroupId: body.id, businessGroupName: body.name },
                            (response) => {
                                cb(response);
                                if (!response.isSuccessful) {
                                    console.error(response);
                                }
                                if (response.isSuccessful) {
                                    this.setState({ formContent: '' });
                                    this.getBusinessGroups(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ businessGroupsList: false }) }} />}

                {(this.state.laundriesList) && <DropdownForm
                    title='Laundries'
                    close={() => { this.setState({ laundriesList: false }) }}>
                    <LaundryPage
                        laundries={this.state.laundries}
                        onChange={(laundries) => {
                            this.setState({ laundries })
                        }} />
                </DropdownForm>}

                {(this.state.garmentTypesList) && <DropdownList
                    list={this.state.garmentTypes}
                    title='Garment Types'
                    onChange={(garmentTypes) => { this.setState({ garmentTypes }) }}
                    apply={(body, cb) => {
                        this.upsertData(
                            api.apiUrl + '/api/DropDowns/UpsertGarmentType',
                            { garmentTypeId: body.id, garmentTypeName: body.name },
                            (response) => {
                                cb(response);
                                if (!response.isSuccessful) {
                                    console.error(response);
                                }
                                if (response.isSuccessful) {
                                    this.setState({ garmentTypeAdd: false, formContent: '' });
                                    this.getGarmentTypes(); // updates list.
                                }
                            });
                    }}
                    close={() => { this.setState({ garmentTypesList: false }) }} />}

                <div>
                    <Row>
                        <DropdownsBlock
                            title='Sites'
                            icon='fal fa-map-marker-alt fa-4x'
                            addClick={() => { this.setState({ siteAdd: true }) }}
                            itemsClick={() => { this.setState({ sitesList: true }) }}
                            response={this.state.response_getSites}
                            items={this.state.sites}
                        />

                        <DropdownsBlock
                            title='Business Groups'
                            icon='fal fa-briefcase fa-4x'
                            addClick={() => { this.setState({ businessGroupAdd: true }) }}
                            itemsClick={() => { this.setState({ businessGroupsList: true }) }}
                            response={this.state.response_getBusinessGroups}
                            items={this.state.businessGroups}
                        />

                        <DropdownsBlock
                            title='Laundries'
                            icon='fal fa-bath fa-4x'
                            addClick={() => { this.setState({ laundryAdd: true }) }}
                            itemsClick={() => { this.setState({ laundriesList: true }) }}
                            response={this.state.response_getLaundries}
                            items={this.state.laundries}
                        />

                    </Row>
                </div>
                <div>
                    <Row>
                        <DropdownsBlock title='Companies'
                            icon='fal fa-address-card fa-4x'
                            addClick={() => { this.setState({ companyAdd: true }) }}
                            itemsClick={() => { this.setState({ companiesList: true }) }}
                            response={this.state.response_getCompanies}
                            items={this.state.companies}
                        />

                        <DropdownsBlock title='Garment Types'
                            icon='fal fa-user-secret fa-4x'
                            addClick={() => { this.setState({ garmentTypeAdd: true }) }}
                            itemsClick={() => { this.setState({ garmentTypesList: true }) }}
                            response={this.state.response_getGarmentTypes}
                            items={this.state.garmentTypes}
                        />

                        <DropdownsBlock title='Shifts'
                            icon='fal fa-list fa-4x'
                            addClick={() => { this.setState({ shiftAdd: true }) }}
                            itemsClick={() => { this.setState({ shiftsList: true }) }}
                            response={this.state.response_getShifts}
                            items={this.state.shifts}
                        />
                    </Row>
                </div>
            </div>
        </div >
    }
}