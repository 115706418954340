import styled from "styled-components";
import React from "react";
import { Button } from "./button";
import { CloudAppResponse } from "../../shared/cloudappresponse";

export function ButtonSave(props : any) {
    return <Button
        text='Save'
        icon='fas fa-save'
        style={{
            padding: 2,
            border: '1px solid rgba(25,150,25,0.2)',
            color: 'rgba(25,150,25,0.6)',
            background: 'rgba(50,150,50,0.1)'
        }}
        {...props}
    />
}

export const TagButtonDiv = styled.div`
    
    background: #3c7bb7;
    padding: 1px 2px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 3px;
    border: 2px solid rgba(0,0,0,0.1);
    display: flex;
    flex-direction: row;
    color: rgba(255,255,255,0.75);
    .name { font-size: 12px; padding: 2px; font-weight: 600;}
    .close { 
        padding-top:2px;
        padding-left: 5px; 
        padding-right: 3px;
        opacity: 0.75; 
        :hover {
            cursor:pointer;
            color: white;
        } 
    }
`;



export class TagButton extends React.Component<{ deleteClick: () => void; children: any }, {}> {
    render() {
        return <div style={{ display: 'inline-block' }}><TagButtonDiv {...this.props} >
            <div className='name'>{this.props.children}</div>
            <div className='close' onClick={() => { this.props.deleteClick(); }}>
                <i className='fal fa-times' />
            </div>
        </TagButtonDiv>
        </div>
    }
}




/******************** */

const TinyButtonDiv = styled.div`
    position: relative;
    top: 0px;
    display: inline-block;
    border: 2px solid rgba(0,0,0,0.00);
    background: rgba(0,100,255,0.025);
    border-radius: 4px;
    font-size: 12px; 
    padding: 3px 6px; font-weight: 600;
    cursor: pointer;
    color: rgba(0,0,0,0.15);
    transition: all 0.1s linear;
    :hover {
        background: rgba(0,100,255,0.1);
        border: 2px solid rgba(60,123,183,0.25);
        color: rgba(60,123,183,0.75);
    }
`;

export function TinyButton(props:any) {
    return <TinyButtonDiv {...props}><i className={props.icon} /> {(props.text) && props.text}</TinyButtonDiv>
}


export const Options = styled.div`
    background: rgba(0,0,0,0.05);
    display: flex;
    flex-direction: row;
    padding: 14px;
`;

export const FormHeaderOptions = styled.div`
    background: rgba(0,0,0,0.05);
    font-weight: 600;
    display: flex;
    flex-direction: row;
`;

interface CloudAppResponseDisplayProps {
    data?: CloudAppResponse<any>
    text?: string
}

export function CloudAppResponseDisplay(props: CloudAppResponseDisplayProps) {
    if (!props.data) return <div>No data.</div>
    return <div>
        {(props.data) && <div style={{ padding: 3 }}>
            {(props.data.isSuccessful === undefined) && <div style={{ color: 'gray' }}><i className='fa fa-circle-notch fa-spin' /> Busy. {props.data.message}</div>}
            {(props.data.isSuccessful === false) && <div style={{ color: 'red' }}><i className='fa fa-times' /> Error. {props.data.exceptionMessage} {props.data.message}</div>}
            {(props.data.isSuccessful === true) && <div style={{ color: 'green' }}><i className='fa fa-check' /> {(props.data.message === null) ? <span>Successful</span> : <span>{props.data.message}</span>} </div>}
        </div>}
    </div>

}

export const Header3 = styled.h3`
    color: rgba(0,0,0,0.75);
    font-weight: 600;
    margin:0;
`

export const Header2 = styled.h2`
    font-weight: 500;
    margin:0;
    color: rgba(255,255,255,0.85);
`;

export const Table = styled.table`
    width: 100%;
    border-spacing: 0px;
    thead {
        font-weight: 800;
        color: rgba(0,0,0,0.7);
        font-size: 10px;
        tr td { 
            padding: 10px 10px 5px 10px;
            border-bottom: 1px solid rgba(0,0,0,0.05);
        }
    }

    tbody {
        min-height: 500px;
    }

    tbody tr {
        transition: all 0.1s linear;
        :hover {            
            background: rgba(0,0,0,0.05);
        }
    }

    tbody tr td {
        padding: 5px 10px;
        border-top: 1px solid rgba(0,0,0,0.05);
        border-bottom: 1px solid rgba(0,0,0,0.05);
        color: rgba(0,0,0,0.6);
        font-weight: 600;
        white-space: nowrap;
        font-size: 10px;
    }
`;

export const FormTable = styled.table`
width: 100%;
border-spacing: 0px;
thead {
    font-weight: 800;
    color: rgba(0,0,0,0.7);
    font-size: 10px;
    tr td { 
        padding: 10px 10px 5px 10px;
    }
}




tbody tr td {
    padding: 5px 10px;
    color: rgba(0,0,0,0.6);
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
}
`;



// Left side of form table like tag allocation page
export const FormLabel = styled.td`
    width: 30%;
    text-align:right;
    white-space: nowrap;
    padding-right: 5px;
`;

export const FormSelect = styled.select`
    padding: 7.5px;
    border: 2px solid rgba(0,0,0,0.1);
    border-radius: 2px;
    width: 100%;
`;

export const InputEdit = styled.input`
    padding: 7.5px;
    border: 2px solid rgba(0,0,0,0.1);
    border-radius: 2px;
    width: 100%;
`;

// RADIO GROUP

export interface RadioGroupProps {
    text?: string
    options: RadioGroupOptions[]
    onChange: (statusOptions: RadioGroupOptions[]) => void
    style?: any
}

export interface RadioGroupOptions { text: string, selected?: boolean }

export class RadioGroup extends React.Component<RadioGroupProps, {}> {
    render() {
        return <div {...{ style: this.props.style }}><div style={{ display: 'flex', flexDirection: 'row' }} >

            {(this.props.text) && <div style={{ color: 'rgba(0,0,0,0.9)', paddingRight: 10 }}>{this.props.text}:</div>}

            {this.props.options.map((option, i) => {

                let style: any = {}
                if (i !== this.props.options.length - 1) {
                    style.paddingRight = 10;
                }


                return <div key={i} style={style}><RadioButton onClick={(e) => {

                    let options = this.props.options.map((opt) => {
                        delete opt.selected;
                        if (opt.text === option.text) { opt.selected = true; }
                        return opt
                    })

                    this.props.onChange(options)
                }} text={option.text} selected={option.selected} /></div>
            })}
        </div></div>
    }
}

const RadioButtonDiv = styled.div`
    user-select:none;
    color: rgba(0,0,0,0.65);
    cursor:pointer;

    :hover {
        color: rgba(0,0,0,1);
        opacity: 1;
    }
`;

interface RadioButtonProps {
    onClick : (e:any)=>void
    text: string
    selected?: boolean
}

export function RadioButton(props:RadioButtonProps) {
    let style: any = {}

    if (props.selected) {
        style = {
            '--fa-primary-opacity': 1,
            '--fa-secondary-opacity': 1,
            '--fa-primary-color': '#87c783',
            '--fa-secondary-color': 'rgba(0,100,0,0.85)'
        }
    } else {
        style = { '--fa-primary-opacity': 0 }
    }



    return <RadioButtonDiv onClick={props.onClick}>
        <i className="fad fa-circle" style={style} /> {props.text}
    </RadioButtonDiv>
}




// SWITCH
const SwitchDiv = styled.div`
    user-select:none;
    color: rgba(0,0,0,0.65);
    cursor:pointer;

    :hover {
        color: rgba(0,0,0,1);
        opacity: 1;
    }
`;

export function Switch(props: { style?: any, text?: string, value: boolean, onChange: (value: boolean) => void }) {
    let style: any = { 'fontSize': '20px', position: 'relative', top: 1 }
    if (props.value) {
        style['--fa-primary-opacity'] = 1;
        style['--fa-secondary-opacity'] = 1;
        style['--fa-primary-color'] = '#87c783';
        style['--fa-secondary-color'] = 'rgba(0,100,0,0.85)';

    } else {
        style['--fa-primary-opacity'] = 0;
    }
    // <i class="fad fa-toggle-on"></i>
    return <SwitchDiv style={props.style} onClick={() => { props.onChange(!props.value) }}>
        <i className={(props.value) ? "fad fa-toggle-on" : "fad fa-toggle-off"} style={style} /> {props.text}
    </SwitchDiv>
}



export const TextButton = styled.span`
    cursor: pointer;
    color: rgba(0,0,0,0.5);
    :hover {
        color: black;
    }
`;


export const theme = {
    brandBlue: '#0d3859'
}

export const primaryButtonStyle = {
    background: theme.brandBlue,
    borderRadius: 5
}


export const SmallText = styled.div`
font-size: 10px;
font-weight: 800;
`;

export const ErrorMessage = styled.div`
    color: red; 
    width: 100%;
    text-align: end;
    padding-right: 0.8em;
`;