import React from 'react';
import { api, encodeQueryData } from '../api';

import { CloudAppResponse } from '../../shared/cloudappresponse';
import { URLSearchParamsObj } from '../utils/urlsearchparams';
import { CustomThemeProvider, DynamicGrid, Theme, BarGraph, ComponentWrapper, GraphDataEntry, ColourKeys, PageResultResponse, ThemesType, PagingArgs} from '@iotnxt-dev/reactcomponents'
import { formatDateYYYYMMDD } from '@iotnxt-dev/shared'
import { conditionalOverrides_EmployeeNumber, conditionalOverrides_ColouredStatus, conditionalOverrrides_FormatDate, conditionalRowStyle_ColoredStatus } from './dynamicGridOverrides';
interface Props {
    theme? : ThemesType
}
interface State {
    chartDataResponse?: CloudAppResponse<GraphDataEntry[]>,
    colourKeys? : ColourKeys
    query?: PagingArgs
}

interface DynamicGridDataModel {
    businessGroup: string
    cacheInsertedDate: string
    cacheItemState: string
    cacheLastUpdatedDate: string
    employeeName: string
    employeeSurname: string
    employeeNumber: string
    garment: string
    id: string
    laundryId: string
    shift: string
    site: string
    state: string
    status: string
    statusDate: string
    tag: string
}

export class Dashboard extends React.Component<Props, State> {
    state: State = {
        chartDataResponse: undefined,
        colourKeys: {
            "in" : api.theme ? api.theme.noAlertStatusColour : '#82d260',
            "out" : api.theme ? api.theme.highAlertStatusColour : '#e06568'
        }
    };

    // unfiltered chart data.
    lastChartDataResponse?:CloudAppResponse<GraphDataEntry[]>;

    componentDidMount = async () => {
        document.title = 'Gijima | Dashboard';
        const chartDataResponse = await this.apiTagGarmentGetChartData('inOrOut',30);
        if (chartDataResponse) this.setState({chartDataResponse})
    }


    apiTagGarmentGetChartData = async (inOrOut: string, days: number): Promise<CloudAppResponse<GraphDataEntry[]>> => {
        if (!api.token) throw new Error('token not ready');
        
        const responseChart : CloudAppResponse<GraphDataEntry[]> = await fetch(`/api/TagGarment/GetChartData?${encodeQueryData({ inOrOut, days })}`, {
            method: 'get',
            headers: { authorization: 'Bearer ' + api.token.access_token }
        }).then(res => res.json())
        if (responseChart.isSuccessful) {
            this.lastChartDataResponse = responseChart;
            return this.applyPagingArgs(responseChart);
        }

        /// If the normal GetChartData fails then we use the alternative..

        const response: PageResultResponse<DynamicGridDataModel> = await fetch(`/api/DynamicGrid/GetPagedInstances`, {
            method: 'post', headers: { 
                'content-type':'application/json',
                authorization: 'Bearer ' + api.token.access_token }, body: JSON.stringify({
                "pageNumber": 1,
                "pageSize": 1000,
                "whereQuery": {},
                "orderByQuery": "statusDate DESC",
                "selectQuery": ""
            })
        }).then(res => res.json())

        if (!response || response.isSuccessful === false) {
            const result : CloudAppResponse<GraphDataEntry[]> = {
                isSuccessful: false,
                message: 'failed',
                data: []
            }
            return result;  
        }

        const chartData : any = {};

        const listOfStatuses :string[] = [];

        response.data.map( row => {
            if (listOfStatuses.includes(row.status)) return null;
            listOfStatuses.push(row.status);
            return null;
        })

        response.data.map( row => {
            const date = formatDateYYYYMMDD(new Date(row.statusDate).toISOString());

            const newEntry : any = { date };
            listOfStatuses.map( statusname => { newEntry[statusname.toLowerCase()] = 0; return null; })

            const existingEntry = (chartData[date]) ? chartData[date] : JSON.parse(JSON.stringify(newEntry));

            if (existingEntry[row.status.toLowerCase()]) {
                existingEntry[row.status.toLowerCase()] += 1;
            } else {
                existingEntry[row.status.toLowerCase()] = 1;
            }
            chartData[date] = existingEntry
            return null;
        })



        const result : CloudAppResponse<GraphDataEntry[]> = {
            isSuccessful: response.isSuccessful,
            message: response.message,
            data: Object.keys(chartData).map( datekey => chartData[datekey])
        }
        this.lastChartDataResponse = result;
        return this.applyPagingArgs(result);        

        
    }
  
    
    /** this filters graph data to what the dynamic grid is set. */
    applyPagingArgs = (request: CloudAppResponse<GraphDataEntry[]>) => {
        if (!request || !request.isSuccessful || !request.data) return request;
        if (!this.state.query) return request;
        if (this.state.query.whereQuery && this.state.query.whereQuery.status) {
            let filter : string = this.state.query.whereQuery.status as string;
            request.data = request.data.map( (data:any) => { 
                Object.keys(data).map( key => {
                    if (!['date',filter,filter.toLowerCase()].includes(key)) delete data[key];
                    return null;
                })
                return data; 
            })
        }
        return request
    }

    render() {
        if (!api.token) return <div>waiting for auth</div>;
        const searchParams = URLSearchParamsObj<any>()        
        delete searchParams.fullscreen;

        return <CustomThemeProvider theme={Theme.defaultThemeData.themes["default"]}>
            <div style={{display:'flex',flexDirection:'row', width:'100%'}}>
            <div style={{ width: '80%'}}>
            <DynamicGrid
                pageSize={20}
                jsonPropertiesOverride={{
                    columns: {
                        "employeeNumber": {
                          "isLocked": false,
                          "displayName": "Emp#",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 0
                        },
                        "id": {
                          "isLocked": false,
                          "displayName": "Tag ID",
                          "type": "String",
                          "values": [],
                          "isVisible": true,
                          "order": 0
                        },
                        "site": {
                          "isLocked": false,
                          "displayName": "Site",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 1
                        },
                        "employeeName": {
                          "isLocked": false,
                          "displayName": "Employee Name",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 2
                        },
                        "laundryId": {
                          "isLocked": false,
                          "displayName": "Laundry Id",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 3
                        },
                        "shift": {
                          "isLocked": false,
                          "displayName": "Shift",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 4
                        },
                        "businessGroup": {
                          "isLocked": false,
                          "displayName": "Business Group",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 5
                        },
                        // "tag": {
                        //   "isLocked": false,
                        //   "displayName": "Tag#",
                        //   "type": "string",
                        //   "values": [],
                        //   "isVisible": true,
                        //   "order": 6
                        // },
                        "statusDate": {
                          "isLocked": false,
                          "displayName": "Status Date",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 7
                        },
                        "status": {
                          "isLocked": false,
                          "displayName": "Status",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 8
                        },
                        "garment": {
                          "isLocked": false,
                          "displayName": "Garment",
                          "type": "string",
                          "values": [],
                          "isVisible": true,
                          "order": 9
                        },
                      }
                }}
                columnControls={false}
                useHistory={false}
                allowEdits={false}
                searchColumnData={searchParams}
                orderByColumn="statusDate"
                orderByColumnSorting="DESC"
                autoRefresh={true}
                showAutoRefreshToggle={true}
                onGetPagedInstances={ async (query) => { 
                    if (this.lastChartDataResponse) {
                        this.setState({chartDataResponse: this.applyPagingArgs(this.lastChartDataResponse)})
                    }
                    console.log('got query data:',query.whereQuery)
                    this.setState({ query });
                    const chartDataResponse = await this.apiTagGarmentGetChartData('inOrOut',30);
                    if (chartDataResponse) this.setState({chartDataResponse})
                }}
                config={{
                    server: "",
                    apiPath: "/api/DynamicGrid",
                    authorization: 'Bearer ' + api.token.access_token
                }} 
                conditionalOverrides={[
                    conditionalOverrides_EmployeeNumber,
                    conditionalOverrides_ColouredStatus,
                    conditionalOverrrides_FormatDate,
                ]}
                conditionalRowStyle={conditionalRowStyle_ColoredStatus}
            />
            </div>

                <ComponentWrapper style={{ width: '20%', padding: 30 }}>
                    {/* Loading spinner */}
                    {this.state.chartDataResponse === undefined && <div style={{ fontSize: 20, height: '100%', textAlign: 'center', opacity: 0.5,justifyContent:'center' }}>
                        <i className="fas fa-circle-notch fa-spin" /></div>}

                    {this.state.chartDataResponse
                        && this.state.chartDataResponse.isSuccessful === false
                        && <span style={{ color: 'rgba(218,100,105,1)'}}>
                          Graph could not be loaded: {this.state.chartDataResponse.exceptionMessage || this.state.chartDataResponse.message}</span>}

                    {this.state.chartDataResponse
                        && this.state.chartDataResponse.data
                        && <BarGraph
                            colourKeys={this.state.colourKeys}
                            data={this.state.chartDataResponse.data} />}
                </ComponentWrapper>

            </div>
        </CustomThemeProvider>
    };

}

export interface TagGarment {
    employeeNumber: string;
    employeeName: string;
    employeeSurname: string;
    tagId: string;
    laundryId: string;
    garmentSize: number;
    garmentType: string;
    allocatedBy: string;
    allocatedDate: string;
}

export interface TagStatusHistory {
    employeeNumber: string;
    employeeName: string;
    employeeSurname: string;
    tagId: string;
    issueDate: string;
    issueType: string;
    tagStatus: string;
    tagLastSeen: string;
}

export interface TagGarmentRequest {
    employeeNumber: string;
    garmentTypeId: string;
    garmentSize: number;
    tagId: string;
    laundryId: string;
    issueDate: string;
    issueType: string;
}
