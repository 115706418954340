import React from "react";
import { api } from "../api";
import styled from "styled-components";
import { Button } from "./button";
import { DropdownForm } from "./dropdownForm";
import { InputEdit, ButtonSave, Table, CloudAppResponseDisplay } from "./styledComponents";
import { CloudAppResponse } from "../../shared/cloudappresponse";
import { Loading } from "./loading";

export interface Permission {
    id: string
    permissionName: string
    pageUrl: string
    changed?: boolean
}


export const Options = styled.div`
    background: rgba(0,0,0,0.05);
    display: flex;
    flex-direction: row;
    padding: 14px;
`;

const Input = styled.input`
    padding:10px;
    border: 1px solid rgba(0,0,0,0.25);
    width: 100%;
`;

interface Props { }
interface State {
    permissions?: Permission[],
    showAddPermissionForm: boolean
    addPermissionForm: Permission
    response_getAllPermissions?: CloudAppResponse<any>
    response_addNewPermission?: CloudAppResponse<any>
}

export class PermissionsPage extends React.Component<Props, State> {

    state: State = {
        permissions: undefined,
        showAddPermissionForm: false,
        addPermissionForm: {
            id: '',
            permissionName: '',
            pageUrl: ''
        },
        response_getAllPermissions: undefined,
        response_addNewPermission: undefined
    }

    componentDidMount() {
        this.getAllPermissions();
        document.title = 'Gijima | Manage Permission'
    }

    getAllPermissions() {
        // api.getAllPermissions(permissions => {
        //     this.setState({ permissions });
        // })

        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Permission/GetAllPermissions',
            { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then(res => res.json()).then((response: CloudAppResponse<Permission[]>) => {

                if (response.isSuccessful && response.data) {
                    let permissions = response.data.sort((a, b) => { if (parseInt(a.id, 10) > parseInt(b.id, 10)) { return 1 } else { return -1 } });
                    this.setState({ permissions });
                } else {
                    this.setState({ permissions: [], response_getAllPermissions: response });
                }


            })
    }

    /** Updates or adds permissions */
    // upsertPermission(permission: Permission) {
    //     this.permissionUpsertPermissionDetail(permission, () => { });
    // }

    permissionUpsertPermissionDetail(permission: Permission, cb: (response: CloudAppResponse<any>) => void) {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Permission/UpsertPermissionDetail',
            {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + api.token.access_token
                }, body: JSON.stringify(permission)
            })
            .then(res => res.json())
            .then((response: CloudAppResponse<any>) => {
                cb(response);
            }).catch(err => { console.error(err); });
    }

    render() {

        let permissions = this.state.permissions;

        return <div style={{ background: 'white' }}>

            {(this.state.showAddPermissionForm) && <DropdownForm
                title='Add New Permission'
                apply={() => {
                    this.setState({ response_addNewPermission: undefined });
                    this.permissionUpsertPermissionDetail(this.state.addPermissionForm, (response) => {
                        if (!response.isSuccessful) {
                            this.setState({ response_addNewPermission: response })
                        }
                        if (response.isSuccessful) {
                            this.setState({ showAddPermissionForm: false })
                            this.getAllPermissions();
                        }
                    })
                }}
                applyButtonIcon='fal fa-plus'
                applyButtonText='Add New Permission'
                close={() => { this.setState({ showAddPermissionForm: false }); }}
            >
                <div>
                    Permission Name:<br />
                    <Input
                        type='text'
                        value={this.state.addPermissionForm.permissionName}
                        onChange={(e) => {
                            let addPermissionForm = this.state.addPermissionForm;
                            addPermissionForm.permissionName = e.target.value;
                            this.setState({ addPermissionForm })
                        }}
                    />
                </div>
                <div>
                    Page url:<br />
                    <Input
                        type='text'
                        value={this.state.addPermissionForm.pageUrl}
                        onChange={(e) => {
                            let addPermissionForm = this.state.addPermissionForm;
                            addPermissionForm.pageUrl = e.target.value;
                            this.setState({ addPermissionForm })
                        }}
                    />
                </div>
                <div style={{ paddingTop: 20, textAlign: 'right' }}><CloudAppResponseDisplay data={this.state.response_addNewPermission} /></div>
            </DropdownForm>}

            <Options>
                <div style={{ flex: 1 }} />
                <div style={{ paddingRight: 10 }}>
                    <Button text='Refresh'
                        onClick={() => {
                            this.setState({ permissions: undefined })
                            this.getAllPermissions();
                        }}
                        style={{
                            color: 'rgba(0,0,0,0.25)',
                            background: 'rgba(0,0,0,0)',
                            border: '2px solid rgba(0,0,0,0)',
                            fontWeight: 600,
                            borderRadius: 5
                        }}
                        icon='fa fa-redo' />
                </div>
                <div>
                    <Button text='Add New Permission'
                        onClick={() => { this.setState({ showAddPermissionForm: true }); }}
                        style={{
                            background: '#0d3859',
                            borderRadius: 5
                        }}
                        icon='fal fa-plus' />
                </div>
            </Options>


            <Table style={{ padding: 0 }}>
                <thead>
                    <tr>
                        <td>id</td>
                        <td>Permission Name</td>
                        <td>Page Url</td>
                        <td style={{ width: 80 }}></td>
                    </tr>
                </thead>

                {(this.state.response_getAllPermissions) && <tbody><tr><td colSpan={99} style={{ textAlign: 'center' }}>
                    Error: {this.state.response_getAllPermissions.exceptionMessage}
                </td></tr></tbody>}

                <tbody>
                    {(permissions === undefined) && <tr><td colSpan={99}><Loading /></td></tr>}
                    {(permissions) && (permissions.length === 0) && <tr><td colSpan={99} style={{ textAlign: 'center' }}>NO DATA</td></tr>}
                    {(permissions) && (permissions.map((permission, i) => {
                        return <tr key={i}>
                            <td>{permission.id}</td>
                            <td><InputEdit type='text' value={permission.permissionName}
                                onChange={(e) => {
                                    permission.permissionName = e.target.value;
                                    permission.changed = true;
                                    this.setState({ permissions })
                                }}
                            /></td>
                            <td><InputEdit
                                type='text'
                                value={permission.pageUrl}
                                onChange={(e) => {
                                    permission.pageUrl = e.target.value;
                                    permission.changed = true;
                                    this.setState({ permissions })
                                }}

                            /></td>
                            <td>
                                {(permission.changed) && <ButtonSave onClick={() => {
                                    delete permission.changed;
                                    this.permissionUpsertPermissionDetail(permission, (response) => { });
                                    this.setState({ permissions })
                                }} />}
                            </td>
                        </tr>
                    }))}
                </tbody>
            </Table>
        </div >
    }
}