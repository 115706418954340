import * as React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import history from './utils/history';
// import { BrowserRouter, Route, Link, NavLink, Switch } from 'react-router-dom';
import { api } from './api';
import { ThemeProvider } from 'styled-components';
import { gijimaTheme, GlobalStyles, Page } from './theme';
import { NavBar } from './components/navbar';
import { Footer } from './components/footer';
import { Welcome } from './components/welcome';
import { AllTags } from './components/tags_alltags';
import { DropdownsPage } from './components/dropdowns';
import { TagsImportPage } from './components/tags_import_page';
import { UsersPage } from './components/users_page';
import { RolesPage } from './components/roles_page';
import { PermissionsPage } from './components/permissions_page';
import { HowToUsePage } from './components/howtouse';
import { Employees } from './components/employees';
import { EmployeePage } from './components/employeePage';
import { Dashboard } from './components/dashboard';
import { URLSearchParamsObj } from './utils/urlsearchparams';

// tslint:disable-next-line: no-empty-interface
interface Props {}
interface State {
    loggedin: boolean;
}

export interface URLSearchProps {
    /** in url put ?fullscreen=true to hide the header and footer */
    fullscreen?: 'true' | 'false';
    site?: string;
}

class App extends React.Component<Props, State> {
    state: State = {
        loggedin: false,
    };

    constructor(props: any) {
        super(props);

        api.on('login', () => {
            this.setState({ loggedin: true });
        });

        api.auth();
    }

    navbar() {
        const preferred_username = ''; // api.account.preferred_username

        return (
            <NavBar
                menu={[
                    {
                        text: 'Dashboard',
                        icon: 'fal fa-desktop',
                        to: '/dashboard',
                    },
                    {
                        text: 'Tags',
                        icon: 'fal fa-tags',
                        direction: 'down',
                        nested: [
                            {
                                text: 'Import Tags',
                                to: '/import-tags',
                                icon: 'fal fa-file-import',
                            },
                            // {
                            //     text: 'Allocate',
                            //     to: '/allocate-tags',
                            //     icon: 'fal fa-user-tag'
                            // },
                            // {
                            //     text: 'Export',
                            //     to: '/export-tags',
                            //     icon: 'fal fa-file-export'
                            // },
                            {
                                text: 'All Tags',
                                to: '/all-tags',
                                icon: 'fal fa-th-list',
                            },
                        ],
                    },
                    {
                        text: 'Employees',
                        to: '/employees',
                        icon: 'fal fa-users',
                    },
                    {
                        text: 'Set Up Dropdowns',
                        to: '/dropdowns',
                        icon: 'fal fa-cog',
                    },
                    {
                        text: 'Users & Roles',
                        icon: 'fal fa-user',
                        direction: 'down',
                        nested: [
                            {
                                text: 'Manage Users',
                                to: '/users',
                                icon: 'fal fa-users-cog',
                            },
                            {
                                text: 'Manage Roles',
                                to: '/manage-roles',
                                icon: 'fal fa-users-class',
                            },
                            {
                                text: 'Manage Permissions',
                                to: '/permissions',
                                icon: 'fal fa-user-shield',
                            },
                        ],
                    },
                    {
                        text: 'Help',
                        icon: 'fal fa-question-circle',
                        direction: 'down',
                        nested: [
                            {
                                text: 'How to Use',
                                icon: 'fal fa-book',
                                to: '/how-to-use',
                            },
                            {
                                text: 'About',
                                icon: 'fal fa-info-circle',
                                to: '/about',
                            },
                        ],
                    },
                    {
                        text: preferred_username,
                        icon: 'fal fa-user',
                        direction: 'down',
                        nested: [
                            {
                                text: 'Logout',
                                icon: 'fal fa-sign-out',
                                onClick: () => {
                                    api.account = undefined;
                                    api.token = undefined;

                                    if (api.commanderUrl)
                                        window.location.replace(
                                            api.commanderUrl
                                        );
                                },
                            },
                        ],
                    },
                ]}
            />
        );
    }

    render() {
        if (!api.account) return <div></div>;

        const searchParams = URLSearchParamsObj<URLSearchProps>();

        let showNavbar = true;
        let showFooter = true;
        if (searchParams.fullscreen === 'true') {
            showNavbar = false;
            showFooter = false;
        }

        if (api.account)
            return (
                <div>
                    <ThemeProvider theme={gijimaTheme}>
                        <GlobalStyles />
                        <Router history={history}>
                            {showNavbar && this.navbar()}

                            <Switch>
                                <Page fullscreen={searchParams.fullscreen}>
                                    <Route exact path="/">
                                        <Welcome />
                                    </Route>

                                    <Route
                                        exact
                                        path="/dashboard"
                                        component={Dashboard}
                                    />

                                    <Route exact path="/import-tags">
                                        <TagsImportPage />
                                    </Route>

                                    <Route exact path="/export-tags">
                                        <h1>Export Tags</h1>
                                    </Route>

                                    <Route exact path="/all-tags">
                                        <AllTags />
                                    </Route>

                                    <Route exact path="/employees">
                                        <Employees />
                                    </Route>

                                    <Route
                                        exact
                                        path="/employees/:employeeNumber"
                                        component={EmployeePage}
                                    />

                                    <Route exact path="/dropdowns">
                                        <DropdownsPage />
                                    </Route>

                                    <Route exact path="/users">
                                        <h1>Users</h1>
                                        <UsersPage />
                                    </Route>

                                    <Route exact path="/manage-roles">
                                        <h1>Manage Roles</h1>
                                        <RolesPage />
                                    </Route>

                                    <Route exact path="/permissions">
                                        <h1>Manage Permissions</h1>
                                        <PermissionsPage />
                                    </Route>

                                    <Route exact path="/how-to-use">
                                        <h1>How to use</h1>
                                        <HowToUsePage />
                                    </Route>

                                    <Route exact path="/about">
                                        <h1>About</h1>
                                        <div style={{ color: 'white' }}>
                                            <p>
                                                Developed for Gijima by IoT.nxt
                                            </p>

                                            <p>Authors:</p>
                                            <ul style={{ fontSize: 12 }}>
                                                <li>
                                                    Sizwe Ntuli (Server side) -
                                                    email:
                                                    sizwe.ntuli@iotnxt.com
                                                </li>
                                                <li>
                                                    Rouan van der Ende (Front
                                                    End) - email:
                                                    rouan.vanderende@iotnxt.com
                                                </li>
                                            </ul>
                                        </div>
                                    </Route>

                                    <Route exact path="/logout">
                                        <h1>Logout</h1>
                                    </Route>
                                </Page>
                            </Switch>
                            {showFooter && <Footer />}
                        </Router>
                    </ThemeProvider>
                </div>
            );
    }
}

export default App;
