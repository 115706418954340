import React from "react";
import styled from "styled-components";

const FooterDiv = styled.div`
    display:flex;
    flex-direction: row;
    font-size: 10px;

    img { height: 50px; }

    div { padding: 5px;}
`;

export function Footer(props?: any) {
    return <FooterDiv>
        <div style={{ flex: 1 }} />
        <div>
            <img src='/footer_wires.gif' alt="background" />
        </div>

        <div style={{
            paddingTop: 15,
            color: 'rgba(255,255,255,0.65)'
        }}>Brought to you by:<br />
        Contact: 016 889 6116
        </div>

        <div>
            <a target='_blank' rel="noreferrer" href='https://www.gijima.com/'><img src='/footer_logo_gijima.gif' alt="gijima logo" /></a>
        </div>

        <div style={{ paddingRight: 30 }}>
            <a target='_blank' rel="noreferrer" href='https://www.iotnxt.com/'><img src='/footer_logo_iotnxt.gif' alt="iotnxt logo" /></a>
        </div>

    </FooterDiv>
}