import { convertHexToRGBA } from '@iotnxt-dev/shared';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

export const colourKeys: any = {
    in: '#82d260',
    out: '#e06568',
};

export const conditionalRowStyle_ColoredStatus = (entry: any) => {
    let style: any = {};
    if (
        colourKeys &&
        entry['status'] &&
        colourKeys[entry['status'].toLowerCase()]
    ) {
        style.backgroundColor = convertHexToRGBA(
            colourKeys[entry['status'].toLowerCase()],
            0.1
        );
    }
    return style;
};


export const conditionalOverrides_EmployeeNumber = {
    onColumnName: 'employeeNumber',
    component: (props: { value: any; row: any }) => {
        return (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Link
                    to={`/employees/${props.row.employeeNumber}`}
                >
                    <i className="fal fa-user-tag" />{' '}
                    {props.row.employeeNumber}
                </Link>
            </div>
        );
    },
}

export const conditionalOverrides_ColouredStatus = {
    onColumnName: 'status',
    component: (props: { value: any; row: any }) => {
        let color = 'gray'; //default
        if (!props.value) return <div></div>
        // do lookup
        if (colourKeys)
            color = colourKeys[
                props.value.toLowerCase()
            ]
                ? colourKeys[
                      props.value.toLowerCase()
                  ]
                : 'gray';
        return (
            <div style={{ color, padding: 5 }}>
                {props.value}
            </div>
        );
    },
}


export const conditionalOverrrides_FormatDate = {
    onColumnName: 'statusDate',
    component: (props: { value: any; row: any }) => {
        return (
            <Moment
                format="YYYY/MM/DD HH:mm:ss"
                style={{
                    whiteSpace: 'nowrap',
                    padding: 5,
                }}
            >
                {props.value}
            </Moment>
        );
    },
}